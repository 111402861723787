import React, { useState, useEffect } from "react";
import  APIContext  from "../APIContext";

function Sandbox() {
  const [patients, setpatients] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await APIContext.get("/patient");
        setpatients(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <div>
      {/* <h1>{data.pageTitle}</h1> */}
      <ul>
        {patients.map(item => (
          <li key={item.id}>{item.id}</li>
        ))}
      </ul>
    </div>
  );
}

export default Sandbox;

