import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  TextInput,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import utc from "dayjs/plugin/utc";
import axios, { all } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import APIParticipant from "../APIParticipant";
import { set } from "date-fns";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

dayjs.extend(utc);

export default function EditEvent({
  editingID,
  close,
  fetchData,
  editOverlayData,
}) {
  const [participant, setParticipant] = useState();

  const [encounterType, setEncounterType] = useState("");
  const [encounterDate, setEncounterDate] = useState(new Date());
  const [encounterStartTime, setEncounterStartTime] = useState(undefined);
  const [encounterEndTime, setEncounterEndTime] = React.useState(undefined);
  const [eventDescription, setEventDescription] = useState("");
  const [participantData, setParticipantData] = useState([]);

  const [idProvidedError, setIdProvidedError] = useState(false);

  const [eventTypeProvidedError, setEventTypeProvidedError] = useState(false);
  const [eventDateProvidedError, setEventDateProvidedError] = useState(false);
  const [eventStartTimeProvidedError, setEventStartTimeProvidedError] =
    useState(false);
  const [eventEndTimeProvidedError, setEventEndTimeProvidedError] =
    useState(false);
  const [eventEndBeforeStartError, setEventEndBeforeStartError] =
    useState(false);

  const { keycloak } = useKeycloak();

  const [viewingParticipant, setViewingParticipant] = useState(1);

  const appointmentEncounterTypes = [
    "Initial contact",
    "Follow-up #1",
    "Follow-up #2",
    "Follow-up #3",
    "Follow-up #4",
    "Close-out",
    "Non-required contact",
    "Accompany to Screening",
  ];

  function clearAll() {
    setParticipant(0);

    setEncounterType(0);
    setEncounterDate(new Date());
    setEncounterStartTime(new Date());
    setEncounterEndTime(new Date());
    setEventDescription("");
  }

  useEffect(() => {
    console.log("Edit event is being rendered");
    const fetchPatientList = async () => {
      try {
        const { data } = await APIParticipant.get(
          "/v1/api/patient"
        );

        setParticipantData(data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (keycloak.tokenParsed.roles.includes("Navigator")) fetchPatientList();
    // setResetPassword(reset);
  }, [keycloak]);

  useEffect(() => {
    if (editOverlayData) {
      console.log("editOverlayData: ", editOverlayData);
      setParticipant(editOverlayData.patient.id);
      setEncounterType(editOverlayData.title);
      setEncounterDate(dayjs(editOverlayData.start));
      // setEncounterStartTime(editOverlayData.start);
      // setEncounterEndTime(dayjs(editOverlayData.end));
      setEventDescription(editOverlayData.description);
    }
  }, [editOverlayData]);

  //Builds participants dropdown selection in event add
  const participantDropdown = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Participant ID
        </Typography>
        <FormControl
          error={idProvidedError}
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: "background.main",
          }}
        >
          <Select
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "background.main",
            }}
            defaultValue={editOverlayData.patient.id}
            value={participant}
            label="Select Participant"
            helperText={idProvidedError ? "participant is required" : ""}
            onChange={(e) => {
              setParticipant(e.target.value);
            }} //setParticipant
          >
            {participantData.map((participant) => (
              <MenuItem value={participant.id} key={participant.id}>
                {participant.participantId}
              </MenuItem>
            ))}
            {/* </>}  */}
          </Select>
          <FormHelperText>
            {idProvidedError ? "Participant is Required" : ""}
          </FormHelperText>
        </FormControl>
      </>
    );
  };

  //Builds encounters dropdown selection
  const encounterDropdown = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Encounter Type
        </Typography>
        <Select
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: "background.main",
          }}
          defaultValue={editOverlayData.title}
          value={encounterType}
          onChange={(e) => {
            setEncounterType(e.target.value);
          }}
        >
          {appointmentEncounterTypes.map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </Select>
      </>
    );
  };

  //Builds date picker
  const datePicker = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Encounter Date
        </Typography>{" "}
        {/* Update Date here*/}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              // label="Basic date picker"
              selected={encounterDate}
              onChange={(newValue) => {
                setEncounterDate(newValue);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </>
    );
  };

  //Builds start and end date dropdowns
  const start_end_dates = () => {
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box sx={{ flex: 1, marginRight: "2.5rem" }}>
            <Typography sx={{ marginTop: 3 }} variant="body1">
              Encounter Start Time
            </Typography>{" "}
            {/* Update Date here*/}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* <DemoContainer components={["TimePicker", "TimePicker"]}> */}
              <TimePicker
                label="   "
                value={encounterStartTime}
                maxTime={encounterEndTime}
                onChange={(newValue) => {
                  setEncounterStartTime(newValue);
                }}
                textField={(props) => <TextField {...props} placeholder="" />}
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ marginTop: 3 }} variant="body1">
              Encounter End Time
            </Typography>{" "}
            {/* Update Date here*/}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* <DemoContainer components={["TimePicker", "TimePicker"]}> */}
              <TimePicker
                label=" "
                value={encounterEndTime}
                minTime={encounterStartTime}
                onChange={(newValue) => {
                  setEncounterEndTime(newValue);
                }}
              />
              {/* </DemoContainer> */}
            </LocalizationProvider>
          </Box>
        </Box>
      </>
    );
  };

  //Builds description text area
  const description = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Description
        </Typography>{" "}
        {/* Update Date here*/}
        <TextField
          rows={3}
          sx={{
            width: "100%",
          }}
          value={eventDescription}
          placeholder={eventDescription}
          multiline
          onChange={(newValue) => setEventDescription(newValue.target.value)}
        />
      </>
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        Edit Appointment
      </Typography>
      {participantDropdown()}
      {encounterDropdown()}
      {datePicker()}
      {start_end_dates()}
      {description()}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            (async () => {
              if (!participant) setIdProvidedError(true);
              if (!encounterType) setEventTypeProvidedError(true);
              if (!encounterDate) setEventDateProvidedError(true);
              if (!encounterStartTime) setEventStartTimeProvidedError(true);
              if (!encounterEndTime) setEventEndTimeProvidedError(true);
              if (encounterEndTime < encounterStartTime)
                setEventEndBeforeStartError(true);

              console.log("adding and editing a meeting");
              try {
                await axios({
                  method: "PUT",
                  url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-event-api/v1/event/${editingID}`,

                  headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                  },
                  data: {
                    title: encounterType,
                    description: eventDescription,
                    editable: true,
                    category: "Meeting",
                    patient: {
                      id: participant,
                    },
                    allDay: false,
                    start: dayjs(
                      dayjs(encounterDate).format("YYYY-MM-DD") +
                        "T" +
                        dayjs(encounterStartTime).format("hh:mm") +
                        ":00.000"
                    )
                      .utc()
                      .toISOString(),
                    end: dayjs(
                      dayjs(encounterDate).format("YYYY-MM-DD") +
                        "T" +
                        dayjs(encounterEndTime).format("hh:mm") +
                        ":00.000"
                    )
                      .utc()
                      .toISOString(),
                    id: editOverlayData.id,
                  },
                });
                close();
                await fetchData();
              } catch (error) {
                alert(error?.response?.data);
              }
            })();
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          Save Changes
        </Button>

        {/* {(eventTypeProvidedError ||
          eventDateProvidedError ||
          eventStartTimeProvidedError ||
          eventEndTimeProvidedError ||
          eventEndBeforeStartError ||
          idProvidedError) && (
          <Alert severity="error" sx={{ width: "100%" }}>
            hello
          </Alert>
        )} */}
      </Box>
    </Box>
  );
}
