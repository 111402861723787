// There is a full link in the Video player files for the setVideoSrc()
import axios from "axios";
import keycloak from "./keycloak"; // Adjust the import path

const API_VIDEO_STREAMING_URL = "https://chw-video-streaming-api.gallaudet.in/";

const APIVideo = axios.create({
  baseURL: API_VIDEO_STREAMING_URL,
});

// Intercept requests and add Keycloak token header
APIVideo.interceptors.request.use(async (config) => {
  const token = await keycloak.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

export default APIVideo;
