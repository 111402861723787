import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  Collapse,
  Box,
  CardHeader,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  ExpandMore,
  ExpandLess,
  VideoChat,
  WarningAmber,
  Token,
} from "@mui/icons-material";
import AddOverlay from "./AddOverlay";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import { set } from "date-fns";

export default function EventCard({
  event,
  handleRemove,
  handleComplete,
  todo = false,
  openAddApp,
  handleEdit,
  setModalData,
  setOverlayType,
}) {
  const { title, start, end, description, meetingLink, status } = event;

  const id = event.patient?.id;
  const participantID = event.patient?.participantId;
  const zoomURL = event.patient?.zoomURL;
  const messageID = event.patient?.appleId;
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const [expanded, setExpanded] = useState(false);

  const renderStatus = (status) => {
    if (status === "PAST_OVERDUE") {
      return (
        <div style={{ width: "16%" }}>
          <Box
            sx={{
              backgroundColor: "background.notificationOverdue",
              marginRight: "1rem",
              textAlign: "center",
              padding: "5px 20px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              justifySelf: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#9C3D00",
                marginRight: "5px",
              }}
              variant="subtitle1"
            >
              Overdue
            </Typography>
            <WarningAmber sx={{ color: "#9C3D00" }} />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                backgroundColor: "#9C3D00",
                color: "#F2D9CA",
                padding: "0px 10px",
                borderRadius: "15px",
                marginLeft: "5px",
              }}
            ></Typography>
          </Box>
        </div>
      );
    } else if (status === "TODO") {
      return null;
    } else if (status === "OVERDUE") {
      return (
        <div style={{ width: "16%" }}>
          <Box
            sx={{
              backgroundColor: "background.notificationWarning",
              marginRight: "1rem",
              textAlign: "center",
              padding: "5px 20px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#8C5D15",
                marginRight: "5px",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              Due Soon
            </Typography>
          </Box>
        </div>
      );
    }
  };

  const handleClickEvent = () => {
    handleEdit(event.id);
    setModalData(event);
    setOverlayType("EditEvent");
    openAddApp();
  };

  const handleClickToDo = () => {
    handleEdit(event.id);
    setModalData(event);
    setOverlayType("EditToDo");
    openAddApp();
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        padding: "5px",
        marginBottom: "25px",
        borderRadius: "15px",
        backgroundColor: "background.main",
        boxShadow: 5,
        maxWidth: "1150px",
      }}
    >
      <CardContent>
        {!todo && (
          <Box sx={{ float: "right" }}>
            <Button
              sx={{
                marginRight: "1rem",
                borderColor: "coloredText.main",
                color: "coloredText.main",
              }}
              variant="outlined"
              size="small"
              onClick={handleClickEvent}
            >
              <EditIcon fontSize="small" />
              Edit
            </Button>
            <Button
              sx={{
                borderColor: "coloredText.main",
                color: "coloredText.main",
              }}
              variant="outlined"
              onClick={handleRemove}
              size="small"
            >
              <DeleteOutlineIcon />
              Remove
            </Button>
          </Box>
        )}
        {todo &&
          (event.status === "DONE" ? (
            <Box
              sx={{
                float: "right",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {renderStatus(event.status)}
              <Button
                sx={{
                  marginRight: "1rem",
                  borderColor: "coloredText.main",
                  color: "coloredText.main",
                }}
                variant="outlined"
                size="small"
                onClick={handleClickToDo}
              >
                <EditIcon fontSize="small" />
                Edit
              </Button>
              <Button
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  justifyContent: "space-around",
                  borderColor: "coloredText.main",
                  color: "coloredText.main",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleComplete();
                }}
                size="small"
              >
                <CheckCircleOutlineIcon />
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                paddingBottom: "10px",
              }}
            >
              {renderStatus(event.status)}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginLeft: "auto",
                }}
              >
                <Button
                  sx={{
                    marginRight: "1rem",
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={handleClickToDo}
                >
                  <EditIcon fontSize="small" />
                  Edit
                </Button>
                <Button
                  sx={{
                    justifyContent: "center",
                    display: "flex",
                    justifyContent: "space-around",
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    handleComplete();
                  }}
                  size="small"
                >
                  <PanoramaFishEyeIcon />
                </Button>
              </Box>
            </Box>
          ))}
        <Typography
          variant="subtitle2"
          color="defaultText.main"
          sx={{ paddingBottom: "18px" }}
          fontSize={14}
        >
          {todo
            ? "Due " + dayjs(end).format("MMMM DD, YYYY")
            : dayjs(start).format("hh:mm") + " - " + dayjs(end).format("hh:mm")}
        </Typography>
        <Typography variant="overline1" sx={{ paddingBottom: "8px" }}>
          {title.toUpperCase()}
        </Typography>
        {!todo && <Typography variant="h6"> With: {participantID}</Typography>}
        {todo && (
          <div>
            <Typography variant="body1"> With: {participantID}</Typography>{" "}
            <Typography variant="body1">{description}</Typography>{" "}
          </div>
        )}
      </CardContent>
      {!todo && (
        <>
          <CardActions>
            <Box sx={{ justifyContent: "flex-end" }}></Box>
            <Button
              size="small"
              sx={{
                marginLeft: "auto",
                color: "coloredText.main",
              }}
              onClick={() => setExpanded(!expanded)}
            >
              SHOW DETAILS {expanded ? <ExpandLess /> : <ExpandMore />}
            </Button>
          </CardActions>

          <Collapse
            in={expanded}
            variant="outlined"
            timeout="auto"
            unmountOnExit
          >
            <Button
              component={Link}
              to={zoomURL}
              onClick={() => {
                navigate("/addNote");
              }}
              target="_blank"
              variant="outlined"
              color="coloredText"
              size="small"
              sx={{ marginLeft: "10px", padding: "10px", marginBottom: "10px" }}
            >
              <VideoChat sx={{ marginRight: "10px" }} /> Start Zoom Meeting
            </Button>
            <Button
              component={Link}
              to={`imessage://${messageID}`}
              target="_blank"
              variant="outlined"
              color="coloredText"
              size="small"
              sx={{ marginLeft: "10px", padding: "10px", marginBottom: "10px" }}
            >
              <VideoChat sx={{ marginRight: "10px" }} /> Message Participant
            </Button>
          </Collapse>
        </>
      )}
    </Card>
  );
}
