import React, { useState, useEffect } from "react";
import { currentPage } from "./currentPage";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Button,
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { LoadingScreen, AddOverlay, NotesCard } from "../components";
import APIParticipant from "../APIParticipant";

// ----------------------------------------------------------------------

const fetchNotes = async (id, setData, setLoading) => {
  try {
    console.log(`notes for navigator # ${id} to seen`);
    const { data } = await APIParticipant.get(`/v1/note/navigator/${id}`);
    // console.log(data.data);
    setData(data);
  } catch (error) {
    console.log(error);
  }
  setLoading(false);
};

export default function NotesPage() {
  currentPage("Notes");
  const theme = useTheme();
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();
  // currentPage("Dashboard");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewingParticipant, setViewingParticipant] = useState("");
  const [displayedNotes, setDisplayedNotes] = useState([]);

  const [value, setValue] = useState("1");
  const [editingID, setEditingID] = useState(-1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = (id) => {
    return () => {
      setEditingID(id);
    };
  };

  useEffect(() => {
    console.log("on load useEffect");
    console.log("keycloak object: ", keycloak);
    fetchNotes(
      keycloak.subject,
      setData,
      setLoading
      //   setError,
    );
  }, [keycloak]);

  useEffect(() => {
    //does appropriate filtering for each view as participant and date changes
    if (data) {
      setDisplayedNotes(
        data.filter(
          (note) =>
            note.parentNoteId === null &&
            (!viewingParticipant.length ||
              note.participant.participantId === viewingParticipant)
        )
      );
    }
  }, [data, viewingParticipant]);

  const [devices, setDevices] = useState("phone");
  const handleDevices = (event, newDevices) => {
    if (newDevices.length) {
      setDevices(newDevices);
    }
  };

  return loading ? (
    <CircularProgress color="primary" />
  ) : (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
    >
      {/* <h1>Dashboard</h1> */}

      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Typography
          sx={{
            lineHeight: 1.3,
            padding: "0.8rem 0",
          }}
          variant="h2"
        >
          Notes
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "2rem 5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            // marginTop: '35px'
          }}
        >
          <Box>
            <Typography sx={{ marginTop: 3 }} variant="body1">
              View Participants:
            </Typography>
            {/* Update Date here*/}
            <Select
              sx={{
                width: 250,
                height: 50,
                backgroundColor: "background.main",
              }}
              value={viewingParticipant}
              label="View Notes for Participant with ID"
              onChange={(e) => setViewingParticipant(e.target.value)}
            >
              <MenuItem value={0}>All Participants</MenuItem>
              {[
                ...new Set(data.map((note) => note.participant.participantId)),
              ].map((participantId) => (
                <MenuItem value={participantId} key={participantId}>
                  {participantId}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            onClick={() => navigation("/addNote")}
            variant="contained"
            sx={{
              marginTop: "auto",
              marginLeft: "auto",
              height: 50,
            }}
          >
            ADD NOTE +{" "}
          </Button>
        </Box>
        <Box
          sx={{
            marginTop: "35px",
          }}
        >
          {displayedNotes.length ? (
            displayedNotes.map((event) => (
              // console.log(event)
              <NotesCard
                handleEdit={handleEdit(event.id)}
                key={event.id}
                event={event}
                // handleRemove={handleRemove(event.id)}
                // openAddApp={openAddApp}
              />
            ))
          ) : (
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
              }}
            >
              No notes created!
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}
