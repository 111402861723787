import React, { useState, useEffect } from "react";
import { Typography, Box, Button, TextField } from "@mui/material";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

import APIParticipant from "../APIParticipant";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

export default function AddNavigator({ close, fetchData }) {
  const [chnId, setChnId] = useState("");
  const [appleId, setAppleId] = useState("");
  const [password, setPassword] = useState("");
  const { keycloak } = useKeycloak();

  const [CHNidProvidedError, setCHNidProvidedError] = useState(false);
  const [appleIdProvidedError, setAppleIdProvidedError] = useState(false);
  const [appleIdSpaceError, setAppleIdSpaceError] = useState(false);
  const [appleIdAtError, setAppleIdAtError] = useState(false);
  const [CHNidSpaceError, setCHNidSpaceError] = useState(false);
  const [CHNidSpecialCharacterError, setCHNidSpecialCharacterError] =
    useState(false);
  const [passwordProvidedError, setPasswordProvidedError] = useState(false);
  const [passwordSpaceError, setPasswordSpaceError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordNoNumberError, setPasswordNoNumberError] = useState(false);

  const appleIdText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Apple ID
        </Typography>
        <TextField
          rows={1}
          type="email"
          sx={{
            width: "100%",
          }}
          value={appleId}
          placeholder=""
          onChange={(e) => {
            setAppleIdSpaceError(
              e.target.value && e.target.value.includes(" ")
            );
            setAppleId(e.target.value);
          }}
          helperText={
            appleIdProvidedError
              ? "Apple ID is required"
              : appleIdSpaceError
              ? "Apple ID cannot contain spaces"
              : appleIdAtError
              ? "Apple ID must include @"
              : ""
          }
          error={appleIdProvidedError || appleIdSpaceError || appleIdAtError}
        />
      </>
    );
  };
  const chnIdText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          CHN ID
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          placeholder=""
          value={chnId}
          helperText={
            CHNidProvidedError
              ? "CHN ID is required"
              : CHNidSpaceError
              ? "CHN ID cannot contain spaces"
              : CHNidSpecialCharacterError
              ? "CHN ID cannot contain special characters"
              : ""
          }
          error={
            CHNidProvidedError || CHNidSpaceError || CHNidSpecialCharacterError
          }
          onChange={(e) => {
            setCHNidSpaceError(e.target.value && e.target.value.includes(" "));
            setCHNidSpecialCharacterError(!alphanumeric.test(chnId));
            setChnId(e.target.value);
          }} //setParticipant
        />
      </>
    );
  };
  const passwordText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Password
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          value={password}
          type="password"
          helperText={
            passwordProvidedError
              ? "Password is required"
              : passwordSpaceError
              ? "Password cannot contain spaces"
              : passwordLengthError
              ? "Password must be between 8 and 20 characters"
              : passwordNoNumberError
              ? "Password must contain a number"
              : ""
          }
          error={
            passwordProvidedError ||
            passwordSpaceError ||
            passwordLengthError ||
            passwordNoNumberError
          }
          onChange={(e) => {
            setPasswordSpaceError(
              e.target.value && e.target.value.includes(" ")
            );
            setPasswordLengthError(
              e.target.value.length > 20 || e.target.value.length < 8
            );
            setPasswordNoNumberError(!/\d/.test(e.target.value));
            setPassword(e.target.value);
          }}
        />
      </>
    );
  };

  function clearAll() {
    setAppleId("");
    setChnId("");
    setPassword("");
  }

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        Add Navigator
      </Typography>

      {chnIdText()}
      {appleIdText()}
      {passwordText()}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            (async () => {
              if (!chnId.trim().length) setCHNidProvidedError(true);
              if (!appleId.trim().length) setAppleIdProvidedError(true);
              if (!appleId.includes("@")) setAppleIdAtError(true);
              if (!password.trim().length) setPasswordProvidedError(true);
              try {
                await APIParticipant.post(
                  "/v1/api/navigator/",
                  {
                    firstName: "xyz12",
                    lastName: "sdsd",
                    password: password,
                    username: chnId,
                    appleId: appleId,
                  }
                );
                // await axios({
                //   method: "POST",
                //   url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/navigator/`,

                //   headers: {
                //     Authorization: `Bearer ${keycloak.token}`,
                //   },
                //   data: {
                //     firstName: "xyz12",
                //     lastName: "sdsd",
                //     password: password,
                //     username: chnId,
                //     appleId: appleId,
                //   },
                // });
                close();
                await fetchData();
              } catch (error) {
                alert("error");
              }
            })();
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          Add Navigator
        </Button>
      </Box>
    </Box>
  );
}
