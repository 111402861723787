// There is a full link in the Video player files for the setVideoSrc()
import axios from "axios";
import keycloak from "./keycloak"; // Adjust the import path

// const API_PARTICIPANT_URL = not ready
const API_EVENT_URL = "https://chw-event-api.gallaudet.in/";

const APIEvent = axios.create({
  baseURL: API_EVENT_URL,
});

// Intercept requests and add Keycloak token header
APIEvent.interceptors.request.use(async (config) => {
  const token = await keycloak.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default APIEvent;
