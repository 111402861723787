import React from "react";
import { Container, Typography } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";

export default function Error({ code, reason }) {
    return (
        <Container
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography variant="h2">Error: {code}</Typography>
            <br />
            <Typography variant="h3">
                {reason ? reason : "Something went wrong"}
            </Typography>
        </Container>
    );
}
