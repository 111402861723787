import React, { useState, useEffect } from "react";
import { currentPage } from "./currentPage";
import { LoadingScreen, Error } from "../components";
import { Typography, Box, Button, TextField } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

import { useNavigate, useParams, Link } from "react-router-dom";
import dayjs from "dayjs";
import APIParticipant from "../APIParticipant";

const fetchNotes = async (navId, setCurrentNotes, noteId, setLoading) => {
  // setLoading(true);
  try {
    console.log(`notes for navigator # ${navId} to seen`);
    const { data } = await APIParticipant.get(`/v1/note/navigator/${navId}`);
    setCurrentNotes([
      data.find((note) => note.id === parseInt(noteId)),
      ...data
        .filter((note) => note.parentNoteId === parseInt(noteId))
        .sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate)),
    ]);
  } catch (error) {
    console.log(error);
  }
  setLoading(false);
};

// const fetchData = async (setData, setError) => {
//   try {
//     const data = await APIContext.get(
//       "/sh-asl-chw-patient-info/v1/api/patient"
//     );
//     // console.log("data here: ", data.data);
//     setData(data.data);
//   } catch (error) {
//     console.log("error: ", error);
//     setError({
//       code: error.response.status,
//       reason: error.response.data.message,
//     });
//   }
// };

export default function AppendNotesPage() {
  // const { editId } = props.params;
  currentPage("Notes");
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [participant, setParticipant] = useState(0);
  const [encounterType, setEncounterType] = useState("Initial contact");
  const [encounterDate, setEncounterDate] = React.useState(new Date());
  const [encounterStartTime, setEncounterStartTime] = React.useState(
    new Date()
  );
  const { id } = useParams();
  const [noteId, setNoteId] = useState(-1);
  const [currentNotes, setCurrentNotes] = useState([]);
  const [appendNoteText, setAppendNoteText] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("on load useEffect");
    console.log("keycloak object: ", keycloak);
    fetchNotes(
      keycloak.subject,
      setCurrentNotes,
      id,
      setLoading

      //   setError,
    );
  }, [keycloak.subject, keycloak, id]);

  // useEffect(() => {}, [data, setNoteId, setCurrentNotes]);

  // useEffect(() => {
  //   if (data.length !== 0) {
  //     for (let i = 0; i < data.length; i++) {
  //       if (data[i].id === parseInt(id)) {
  //         setNoteId(i);
  //         console.log(data[i]);
  //         currentNotes.push(data[i]);
  //       }
  //     }
  //     for (let i = 0; i < data.length; i++) {
  //       if (data[i].parentNoteId === parseInt(id)) {
  //         currentNotes.push(data[i]);
  //       }
  //     }
  //   }
  //   console.log(currentNotes);
  // }, [data]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
    >
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <button onClick={() => navigation("/notes")}>
          <Typography variant="backButton">{"<< BACK TO NOTES"}</Typography>
        </button>
        <Typography
          sx={{
            lineHeight: 1.2,
            padding: "0.8rem 0",
          }}
          variant="h4"
        >
          {`PARTICIPANT: ${currentNotes[0].participant.participantId} `}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.2,
            padding: "0.8rem 0",
          }}
          variant="h4"
        >
          {`ENCOUNTER TYPE: ${currentNotes[0].noteType}`}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.2,
            padding: "0.8rem 0",
          }}
          variant="h4"
        >
          {`DATE CREATED: ${dayjs(currentNotes[0].eventDate).format(
            "MM/DD/YYYY"
          )}`}
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "0rem 5rem",
        }}
      >
        <Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            {/* <Typography sx={{ marginTop: 3 }} variant="body1">
                                Description
                            </Typography>{" "} */}
            {/* Update Date here*/}
            {/* <TextField
              rows={20}
              sx={{
                marginTop: "3rem",
                width: "100%",
              }}
              placeholder=""
              multiline
              disabled
              value={currentNotes}
              // onChange={(newValue) => setCurrentNotes(newValue.target.value)}
            /> */}
            <Box
              sx={{
                marginTop: "3rem",
                height: "auto",
                borderColor: "gray",
                borderWidth: "1px",
                borderStyle: "solid",
                borderRadius: "4px",
                padding: "1rem",
                overflow: "scroll",
              }}
            >
              {currentNotes.map((item) => (
                <div key={item.id}>
                  <Typography>
                    {item.parentNoteId === null
                      ? "Note Created  - " +
                        dayjs(item.createdDate).format(
                          "MMMM DD, YYYY hh:mm:ss A"
                        )
                      : "Note Appended - " +
                        dayjs(item.createdDate).format(
                          "MMMM DD, YYYY hh:mm:ss A"
                        )}
                  </Typography>
                  <Typography
                    sx={{ padding: "1rem 0", color: "defaultText.main" }}
                  >
                    {item.description}
                  </Typography>
                </div>
              ))}
            </Box>

            <TextField
              rows={5}
              sx={{
                marginTop: "3rem",
                width: "100%",
              }}
              placeholder="Type here to append to note"
              multiline
              value={appendNoteText}
              onChange={(e) => setAppendNoteText(e.target.value)}
            />
          </Box>
          <Box sx={{ margin: "1.5rem 0", float: "right" }}>
            <Button
              onClick={() => navigation("/notes")}
              variant="outlined"
              sx={{
                margin: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "coloredText.main",
                color: "coloredText.main",
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                (async () => {
                  if (!appendNoteText.trim().length) {
                    alert("Note text cannot be empty!");
                  } else {
                    setLoading(true);
                    await APIParticipant.post("/v1/note", {
                      ...currentNotes[0],
                      parentNoteId: id,
                      description: appendNoteText,
                    });
                    await fetchNotes(
                      keycloak.subject,
                      setCurrentNotes,
                      id,
                      setLoading
                    );
                  }
                  setAppendNoteText("");
                  navigation("/notes");
                })();
              }}
              variant="contained"
              sx={{
                margin: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "coloredText.main",
                color: "white",
              }}
            >
              ADD TO NOTE
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
