import React, { useState, useEffect } from "react";
import { currentPage } from "./currentPage";
import dayjs from "dayjs";

// components
import { LoadingScreen, AddOverlay, ParticipantCard } from "../components";

import {
  Typography,
  Box,
  Tab,
  Select,
  MenuItem,
  Button,
  Backdrop,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { event } from "jquery";
import AddParticipant from "../components/AddParticipant";
import AddNavigator from "../components/AddNavigator";
import { Add, Edit } from "@mui/icons-material";
import EditParticipant from "../components/EditParticipant";
import OverlayPicker from "../components/OverlayPicker";
import { set } from "date-fns";
import APIParticipant from "../APIParticipant";

// const fetchAllData =
//   (setData, setLoading, setError, setNavigatorData) => async () => {
//     await fetchData(setData, setLoading, setError);
//     await fetchNavData(setNavigatorData, setLoading, setError);
//   };

const fetchData = async (setData, setNavigatorData, setLoading, setError) => {
  setLoading(true);
  try {
    const participantData = await APIParticipant.get(
      "/v1/api/patient/findall/"
    );
    console.log(" p data: ", participantData.data);
    setData(participantData.data);
    const navigatorData = await APIParticipant.get("/v1/api/navigator/");
    console.log("dataNav: ", navigatorData.data);
    setNavigatorData(navigatorData.data);
  } catch (error) {
    console.log("error: ", error);
  }
  setLoading(false);
};

export default function AdminPage() {
  const theme = useTheme();
  currentPage("Admin");
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();
  const [data, setData] = useState([]);
  const [navigatorData, setNavigatorData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(undefined);

  const [date, setDate] = useState(dayjs());
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [displayedParticipants, setDisplayedParticipants] = useState([]);
  const [viewingParticipant, setViewingParticipant] = useState(0);

  const [displayedNavigators, setDisplayedNavigators] = useState([]);
  const [viewingNavigator, setViewingNavigator] = useState(0);
  const [overlayType, setOverlayType] = useState("");
  const [reset, setReset] = useState(0);

  const [editOverlayData, setEditOverlayData] = useState({});

  useEffect(() => {
    console.log("editOverlayData: ", editOverlayData);
  }, [editOverlayData]);

  // const [value, setValue] = React.useState('1');
  const [editingID, setEditingID] = useState(-1);
  const [tabState, setTabState] = useState("1");

  const handleTabs = (value) => {
    setTabState(value);
    return;
  };

  useEffect(() => {
    console.log("type: ", overlayType);
  }, [overlayType]);

  const handleEdit = (id) => {
    return () => {
      setEditingID(id);
    };
  };

  const handleResetPassword = () => {
    setReset(1);
    openAddApp();
  };

  const handleRemove = (id) => {
    return async () => {
      console.log("removing");
      try {
        await APIParticipant.delete(`v1/api/patient/${id}`);
        await fetchData(setData, setNavigatorData, setLoading, setError);
      } catch (error) {
        console.log(error);
      }
    };
  };

  const handleNavigatorDelete = (id) => {
    return async () => {
      console.log("removing");
      try {
        await APIParticipant.delete(`/v1/api/navigator/${id}`);
        await fetchData(setData, setNavigatorData, setLoading, setError);
      } catch (error) {
        console.log(error);
      }
    };
  };

  const [open, setOpen] = useState(false);
  const closeAddApp = () => {
    setOpen(false);
    setEditingID(-1);
    setReset(0);
  };
  const openAddApp = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log("on load useEffect");
    console.log("keycloak object: ", keycloak);
    fetchData(setData, setNavigatorData, setLoading, setError);
  }, []);

  useEffect(() => {
    //does appropriate filtering for each view as participant and date changes
    if (data) {
      setDisplayedParticipants(
        data.filter(
          (event) => !viewingParticipant || event.id === viewingParticipant
        )
      );
    }

    if (navigatorData) {
      console.log(navigatorData);
      setDisplayedNavigators(
        navigatorData.filter(
          (event) => !viewingNavigator || event.id === viewingNavigator
        )
      );
    }
    console.log(displayedParticipants);
  }, [data, navigatorData, viewingParticipant, viewingNavigator]);

  useEffect(() => {
    fetchData(setData, setNavigatorData, setLoading, setError);
  }, []);

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
      // maxWidth={false}
    >
      {/* <h1>Dashboard</h1> */}
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Box
          sx={
            {
              // display: "flex",
              // justifyContent: 'flex-start',
            }
          }
        >
          <TabContext value={tabState}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                value={tabState}
                onChange={(event, value) => {
                  handleTabs(value);
                }}
                textColor="Black"
                aria-label=""
              >
                <Tab label="Participants" value="1" />
                <Tab label="Navigators" value="2" />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                padding: "0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "35px",
                  marginBottom: 3,
                }}
              >
                <Box>
                  <Typography sx={{ marginTop: 3 }} variant="body1">
                    View Participants:
                  </Typography>
                  {/* Update Date here*/}
                  <Select
                    sx={{
                      width: 500,
                      height: 50,
                      backgroundColor: "background.main",
                    }}
                    value={viewingParticipant}
                    label="View Information for Participant with ID"
                    onChange={(e) => setViewingParticipant(e.target.value)}
                  >
                    <MenuItem value={0}>All Participants</MenuItem>
                    {data.map((participant) => (
                      <MenuItem value={participant.id} key={participant.id}>
                        {participant.participantId}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOverlayType("AddParticipant");
                    openAddApp();
                  }}
                  sx={{
                    alignSelf: "flex-end",
                    marginLeft: "auto",
                    height: 50,
                  }}
                >
                  ADD Participant +{" "}
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <Box
                    sx={{
                      padding: "2.5rem 5rem",
                      backgroundColor: "background.main",
                      width: "70%",
                      height: "70%",
                      borderRadius: "15px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <OverlayPicker
                      editingID={editingID}
                      data={navigatorData}
                      editOverlayData={editOverlayData}
                      setModalData={setEditOverlayData}
                      name="Participant"
                      event={{
                        id: 1,
                        title: "text",
                        participant: "text",
                        appleId: "email",
                        chnId: "dropdown",
                        zoomLink: "text",
                      }}
                      type={overlayType}
                      close={closeAddApp}
                      fetchData={async () => {
                        await fetchData(
                          setData,
                          setNavigatorData,
                          setLoading,
                          setError
                        );
                      }}
                    />
                    {/* PUT MODAL CHOOSER HERE */}
                  </Box>
                </Backdrop>
              </Box>
              <Box>
                {displayedParticipants.map((participant) => (
                  <ParticipantCard
                    setModalData={setEditOverlayData}
                    key={participant.id}
                    id={participant.id}
                    page={"admin"}
                    participantId={participant.participantId}
                    age={participant.age}
                    zoomURL={participant.zoomURL}
                    appleId={participant.appleId}
                    CHNusername={participant.navigators[0].username}
                    handleEdit={handleEdit(participant.id)}
                    openAddApp={openAddApp}
                    participant={participant}
                    handleRemove={handleRemove(participant.id)}
                    overlayType={overlayType}
                    setOverlayType={setOverlayType}
                  />
                ))}
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                padding: "0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "35px",
                  marginBottom: 3,
                }}
              >
                <Box>
                  <Typography sx={{ marginTop: 3 }} variant="body1">
                    View Navigators:
                  </Typography>
                  {/* Update Date here*/}
                  <Select
                    sx={{
                      width: 500,
                      height: 50,
                      backgroundColor: "background.main",
                    }}
                    value={viewingNavigator}
                    defaultValue={parseInt(viewingNavigator)}
                    label="View Information for Navigator with ID"
                    onChange={(e) => {
                      setViewingNavigator(e.target.value);
                      console.log(viewingNavigator);
                    }}
                  >
                    <MenuItem value={0}>All Navigators</MenuItem>
                    {displayedNavigators.map((navigator) => (
                      <MenuItem value={navigator.id} key={navigator.id}>
                        {navigator.username}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Button
                  variant="contained"
                  onClick={() => {
                    setOverlayType("AddNavigator");
                    openAddApp();
                  }}
                  sx={{
                    alignSelf: "flex-end",
                    marginLeft: "auto",
                    height: 50,
                  }}
                >
                  ADD Navigator +{" "}
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <Box
                    sx={{
                      padding: "2.5rem 5rem",
                      backgroundColor: "background.main",
                      width: "70%",
                      height: "70%",
                      borderRadius: "15px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <OverlayPicker
                      type={overlayType}
                      setModalData={setEditOverlayData}
                      editOverlayData={editOverlayData}
                      editingID={editingID}
                      data={navigatorData}
                      name="Navigator"
                      event={{
                        id: 1,
                        title: "text",
                        chnId: "text",
                        appleId: "email",
                        password: "password",
                      }}
                      close={closeAddApp}
                      fetchData={async () => {
                        await fetchData(
                          setData,
                          setNavigatorData,
                          setLoading,
                          setError
                        );
                      }}
                    />
                  </Box>
                </Backdrop>
              </Box>
              <Box>
                {displayedNavigators.map((navigator) => (
                  <ParticipantCard
                    key={navigator.id}
                    data={navigatorData}
                    page={"navigator"}
                    id={navigator.id}
                    username={navigator.username}
                    NavAppleId={navigator.appleId}
                    handleRemove={handleNavigatorDelete(navigator.id)}
                    handleResetPassword={handleResetPassword}
                    setModalData={setEditOverlayData}
                    setOverlayType={setOverlayType}
                    openAddApp={openAddApp}
                    // password={navigator.password}
                  />
                ))}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}
