import React, { useState, useEffect, useRef, useCallback } from "react";
import { currentPage } from "./currentPage";
import {
  Typography,
  Container,
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Button,
  Card,
  CardActions,
  CardContent,
  Backdrop,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  AdapterDayjs,
} from "@mui/x-date-pickers";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import {
  LoadingScreen,
  EventCard,
  Calendar,
  AddOverlay,
  Error,
} from "../components";
import dayjs from "dayjs";
import APIEvent from "../APIEvent";

const fetchEventData = async (setData, setLoading, setError) => {
  try {
    const data = await APIEvent.get("/v1/event/Meeting");
    console.log(data.data);

    setData(data.data);
  } catch (error) {
    console.log(error);
    setError({
      code: error.response.status,
      reason: error.response.data.message,
    });
  }
  setLoading(false);
};

export default function CalendarPage() {
  currentPage("Dashboard");
  const theme = useTheme();
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();
  const [data, setData] = useState(undefined);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("1");
  const [error, setError] = useState(null);
  const [viewingParticipant, setViewingParticipant] = useState(0);
  const [date, setDate] = useState(dayjs());

  const weekCalendarRef = useRef(null);
  const monthCalendarRef = useRef(null);

  const [editingID, setEditingID] = useState(-1);

  const [open, setOpen] = useState(false);
  const closeAddApp = () => {
    setOpen(false);
  };
  const openAddApp = () => {
    setOpen(true);
  };

  const handleEdit = (id) => {
    return () => {
      setEditingID(id);
    };
  };

  const handleRemove = (id) => {
    return async () => {
      console.log("removing");
      try {
        await APIEvent.delete(`sh-asl-chw-event-api/v1/event/${id}`);
        await fetchEventData(setData, setLoading, setError);
      } catch (error) {
        console.log(error);
      }
    };
  };

  useEffect(() => {
    console.log("on load useEffect");
    fetchEventData(setData, setLoading, setError);
  }, [keycloak.token]);

  useEffect(() => {
    //does appropriate filtering for each view as participant and date changes
    if (data) {
      setDisplayedEvents(
        data.filter(
          (event) =>
            !viewingParticipant || event.patient.id === viewingParticipant
        )
      );

      if (tabValue === "1") {
        setDisplayedEvents((display) =>
          display
            .filter((event) => dayjs(event.start).isSame(date, "day"))
            .sort((firstEvent, secondEvent) =>
              dayjs(firstEvent.start).isSame(secondEvent.start, "minute")
                ? 0
                : dayjs(firstEvent.start).isBefore(secondEvent.start, "minute")
                ? -1
                : 1
            )
        );
      }
    }
  }, [tabValue, date, viewingParticipant, data]);

  useEffect(() => {
    //updates the month and week calendar to the current date when the date changes
    monthCalendarRef.current?.getApi().gotoDate(date.toISOString());
    weekCalendarRef.current?.getApi().gotoDate(date.toISOString());
  }, [date]);

  if (loading) return <LoadingScreen />;
  if (error) return <Error code={error.code} reason={error.reason} />;

  return (
    // <Box
    //   sx={{
    //     position: "absolute",
    //     top: "153px",
    //     left: 0,
    //     width: "100%",
    //     backgroundColor: "background.main",
    //   }}
    // >
    //   <Box
    //     sx={{
    //       padding: "2.5rem 5rem",
    //     }}
    //   >
    //     <button onClick={() => navigation("/")}>
    //       <Typography variant="backButton">{"<< BACK TO DASHBOARD"}</Typography>
    //     </button>
    //     <Typography
    //       sx={{
    //         lineHeight: 1.3,
    //         padding: "0.8rem 0",
    //       }}
    //       variant="h2"
    //     >
    //       Calendar
    //     </Typography>
    //   </Box>
    //   <Box
    //     sx={{
    //       padding: "2.5rem 5rem",
    //     }}
    //   >
    //     <Box>
    //       <TabContext value={tabValue}>
    //         <Box
    //           sx={{
    //             borderBottom: 1,
    //             borderColor: "divider",
    //           }}
    //         >
    //           <TabList
    //             onChange={(event, newValue) => {
    //               setTabValue(newValue);
    //             }}
    //             textColor="coloredText"
    //             aria-label=""
    //           >
    //             <Tab label="Day" value="1" />
    //             <Tab label="Week" value="2" />
    //             <Tab label="Month" value="3" />
    //           </TabList>
    //         </Box>
    //         <TabPanel
    //           value="1"
    //           sx={{
    //             padding: "0",
    //           }}
    //         >
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "flex-start",
    //               marginTop: "35px",
    //             }}
    //           >
    //             <DatePicker
    //               label={"Change Date"}
    //               views={["day"]}
    //               value={date}
    //               onChange={setDate}
    //               format="MMMM DD, YYYY"
    //             />

    //             <Button
    //               variant="contained"
    //               onClick={openAddApp}
    //               sx={{
    //                 margin: "10px",
    //                 marginLeft: "auto",
    //               }}
    //             >
    //               ADD APPOINTMENT +
    //             </Button>
    //             <Backdrop
    //               sx={{
    //                 color: "#fff",
    //                 zIndex: (theme) => theme.zIndex.drawer + 1,
    //               }}
    //               open={open}
    //             >
    //               <Box
    //                 sx={{
    //                   padding: "2.5rem 5rem",
    //                   backgroundColor: "background.main",
    //                   width: "70%",
    //                   height: "70%",
    //                   borderRadius: "15px",
    //                   overflow: "hidden",
    //                   overflowY: "scroll",
    //                 }}
    //               >
    //                 <AddOverlay
    //                   editingID={editingID}
    //                   name="Appointment"
    //                   event={{
    //                     id: 1,
    //                     title: "text",
    //                     start_end: "dropdown",
    //                     date: "Encounter",
    //                     editable: "true",
    //                     category: "dropdown",
    //                     // description: "text",
    //                     participant: "dropdown",
    //                   }}
    //                   close={closeAddApp}
    //                 />
    //               </Box>
    //             </Backdrop>
    //           </Box>
    //           <Box>
    //             <Typography sx={{ marginTop: 3 }} variant="body1">
    //               View Participants:
    //             </Typography>
    //             <Select
    //               sx={{
    //                 width: 250,
    //                 height: 50,
    //                 backgroundColor: "background.main",
    //               }}
    //               value={viewingParticipant}
    //               label="View Events for Participant with ID"
    //               onChange={(e) => setViewingParticipant(e.target.value)}
    //             >
    //               <MenuItem value={0}>All Participants</MenuItem>
    //               {[...new Set(data.map((event) => event.patient.id))].map(
    //                 (id) => (
    //                   <MenuItem value={id} key={id}>
    //                     Participant {id}
    //                   </MenuItem>
    //                 )
    //               )}
    //             </Select>
    //           </Box>
    //           <Box
    //             sx={{
    //               marginTop: "35px",
    //             }}
    //           >
    //             {displayedEvents.length ? (
    //               displayedEvents.map((event) => (
    //                 <EventCard
    //                   key={event.id}
    //                   event={event}
    //                   handleEdit={handleEdit(event.id)}
    //                   handleRemove={handleRemove(event.id)}
    //                   openAddApp={openAddApp}
    //                 />
    //               ))
    //             ) : (
    //               <Typography
    //                 variant="h2"
    //                 sx={{
    //                   textAlign: "center",
    //                 }}
    //               >
    //                 Nothing Today!
    //               </Typography>
    //             )}
    //           </Box>
    //         </TabPanel>
    //         <TabPanel
    //           value="2"
    //           sx={{
    //             padding: "0",
    //           }}
    //         >
    //           <Box>
    //             <Typography sx={{ marginTop: 3 }} variant="body1">
    //               View Participants:
    //             </Typography>
    //             <Select
    //               sx={{
    //                 width: 250,
    //                 height: 50,
    //                 backgroundColor: "background.main",
    //               }}
    //               value={viewingParticipant}
    //               label="View Events for Participant with ID"
    //               onChange={(e) => setViewingParticipant(e.target.value)}
    //             >
    //               <MenuItem value={0}>All Participants</MenuItem>
    //               {[...new Set(data.map((event) => event.patient.id))].map(
    //                 (id) => (
    //                   <MenuItem value={id} key={id}>
    //                     Participant {id}
    //                   </MenuItem>
    //                 )
    //               )}
    //             </Select>
    //           </Box>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "flex-start",
    //               marginTop: "35px",
    //             }}
    //           >
    //             <Button
    //               variant="outlined"
    //               sx={{
    //                 margin: "10px",
    //                 borderWidth: "1px",
    //                 borderStyle: "solid",
    //                 borderColor: "coloredText.main",
    //                 color: "coloredText.main",
    //               }}
    //               onClick={() => {
    //                 //set date one week back
    //                 setDate(date.subtract(1, "week"));
    //               }}
    //             >
    //               Previous Week
    //             </Button>
    //             <Button
    //               variant="outlined"
    //               sx={{
    //                 margin: "10px",
    //                 borderWidth: "1px",
    //                 borderStyle: "solid",
    //                 borderColor: "coloredText.main",
    //                 color: "coloredText.main",
    //               }}
    //               onClick={() => {
    //                 setDate(date.add(1, "week"));
    //               }}
    //             >
    //               Next Week
    //             </Button>
    //             <Button
    //               variant="contained"
    //               onClick={openAddApp}
    //               sx={{
    //                 margin: "10px",
    //                 marginLeft: "auto",
    //               }}
    //             >
    //               ADD APPOINTMENT +
    //             </Button>
    //             <Backdrop
    //               sx={{
    //                 color: "#fff",
    //                 zIndex: (theme) => theme.zIndex.drawer + 1,
    //               }}
    //               open={open}
    //             >
    //               <Box
    //                 sx={{
    //                   padding: "2.5rem 5rem",
    //                   backgroundColor: "background.main",
    //                   width: "70%",
    //                   height: "70%",
    //                   borderRadius: "15px",
    //                   overflow: "hidden",
    //                   overflowY: "scroll",
    //                 }}
    //               >
    //                 <AddOverlay
    //                   editingID={editingID}
    //                   name="Appointment"
    //                   event={{
    //                     id: 1,
    //                     title: "text",
    //                     start_end: "dropdown",
    //                     date: "Encounter",
    //                     editable: "true",
    //                     category: "dropdown",
    //                     // description: "text",
    //                     participant: "dropdown",
    //                   }}
    //                   close={closeAddApp}
    //                 />
    //               </Box>
    //             </Backdrop>
    //           </Box>
    //           <Box
    //             sx={{
    //               marginTop: "35px",
    //             }}
    //           >
    //             <Calendar
    //               ref={weekCalendarRef}
    //               events={displayedEvents}
    //               view="timeGridWeek"
    //               initialDate={date}
    //             />
    //           </Box>
    //         </TabPanel>
    //         <TabPanel
    //           value="3"
    //           sx={{
    //             padding: "0",
    //           }}
    //         >
    //           <Box>
    //             <Typography sx={{ marginTop: 3 }} variant="body1">
    //               View Participants:
    //             </Typography>
    //             <Select
    //               sx={{
    //                 width: 250,
    //                 height: 50,
    //                 backgroundColor: "background.main",
    //               }}
    //               value={viewingParticipant}
    //               label="View Events for Participant with ID"
    //               onChange={(e) => setViewingParticipant(e.target.value)}
    //             >
    //               <MenuItem value={0}>All Participants</MenuItem>
    //               {[...new Set(data.map((event) => event.patient.id))].map(
    //                 (id) => (
    //                   <MenuItem value={id} key={id}>
    //                     Participant {id}
    //                   </MenuItem>
    //                 )
    //               )}
    //             </Select>
    //           </Box>
    //           <Box
    //             sx={{
    //               display: "flex",
    //               justifyContent: "flex-start",
    //               marginTop: "35px",
    //             }}
    //           >
    //             <DatePicker
    //               label={"Change Month"}
    //               views={["month"]}
    //               value={date}
    //               onChange={setDate}
    //             />
    //             <Button
    //               variant="contained"
    //               onClick={openAddApp}
    //               sx={{
    //                 margin: "10px",
    //                 marginLeft: "auto",
    //               }}
    //             >
    //               ADD APPOINTMENT +
    //             </Button>
    //             <Backdrop
    //               sx={{
    //                 color: "#fff",
    //                 zIndex: (theme) => theme.zIndex.drawer + 1,
    //               }}
    //               open={open}
    //             >
    //               <Box
    //                 sx={{
    //                   padding: "2.5rem 5rem",
    //                   backgroundColor: "background.main",
    //                   width: "70%",
    //                   height: "70%",
    //                   borderRadius: "15px",
    //                   overflow: "hidden",
    //                   overflowY: "scroll",
    //                 }}
    //               >
    //                 <AddOverlay
    //                   editingID={editingID}
    //                   name="Appointment"
    //                   event={{
    //                     id: 1,
    //                     title: "text",
    //                     start_end: "dropdown",
    //                     date: "Encounter",
    //                     editable: "true",
    //                     category: "dropdown",
    //                     // description: "text",
    //                     participant: "dropdown",
    //                   }}
    //                   close={closeAddApp}
    //                 />
    //               </Box>
    //             </Backdrop>
    //           </Box>
    //           <Box
    //             sx={{
    //               marginTop: "35px",
    //             }}
    //           >
    //             <Calendar
    //               ref={monthCalendarRef}
    //               events={displayedEvents}
    //               view="dayGridMonth"
    //               initialDate={date}
    //             />
    //           </Box>
    //         </TabPanel>
    //       </TabContext>
    //     </Box>
    //   </Box>
    // </Box>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Typography sx={{ padding: "10rem" }} variant="h2">
        Calendar under construction
      </Typography>
    </Box>
  );
}
