import React, { useState, useEffect, useRef } from "react";
import { currentPage } from "./currentPage";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useKeycloak } from "@react-keycloak/web";

import APIParticipant from "../APIParticipant";
import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { PostAdd } from "@mui/icons-material";

import { set } from "date-fns";
import APIEvent from "../APIEvent";

const fetchData = async (setParticipants, setError, setSelectedParticipant) => {
  try {
    const { data } = await APIParticipant.get(
      "/sh-asl-chw-patient-info/v1/api/patient"
    );
    // console.log("data here: ", data.data);
    setParticipants((oldParticipants) => data);
    setSelectedParticipant(data[0].id);
  } catch (error) {
    console.log("error: ", error);
  }
};

const appointmentEncounterTypes = [
  "Initial contact",
  "Follow-up #1",
  "Follow-up #2",
  "Follow-up #3",
  "Follow-up #4",
  "Close-out",
  "Non-required contact",
  "Accompany to Screening",
];

export default function AddNotesPage() {
  // const { editId } = props.params;
  currentPage("Notes");
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();
  const [error, setError] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(0);
  const [participants, setParticipants] = useState([]);
  const [encounterType, setEncounterType] = useState("Initial contact");
  const [loading, setLoading] = useState(true);
  const [encounterDate, setEncounterDate] = useState(dayjs());
  const [encounterStartTime, setEncounterStartTime] = useState(dayjs());
  const [noteText, setNoteText] = useState("");

  // const [open, setOpen] = React.useState(false);
  // const closeAddApp = () => {
  //     setOpen(false);
  // };
  // const openAddApp = () => {
  //     setOpen(true);
  // };

  useEffect(() => {
    console.log("on load useEffect");
    fetchData(setParticipants, setError, setSelectedParticipant);
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
    >
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <button onClick={() => navigation("/notes")}>
          <Typography variant="backButton">{"<< BACK TO NOTES"}</Typography>
        </button>
        <Typography
          sx={{
            lineHeight: 1.3,
            padding: "0.8rem 0",
          }}
          variant="h2"
        >
          Add Note
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Box>
          <Box>
            <Typography sx={{ marginTop: 3 }} variant="body1">
              Participant ID
            </Typography>
            <Select
              sx={{
                width: "45%",
                height: 50,
                backgroundColor: "background.main",
              }}
              value={selectedParticipant}
              label="Select Participant"
              onChange={(e) => {
                setSelectedParticipant(e.target.value);
              }} //setParticipant
            >
              {/* <MenuItem value={0}>Select Participant</MenuItem> */}
              {participants.map((participant) => (
                <MenuItem value={participant.id} key={participant.id}>
                  {participant.participantId}
                </MenuItem>
              ))}
            </Select>
            <Typography sx={{ marginTop: 3 }} variant="body1">
              Encounter Type
            </Typography>
            <Select
              sx={{
                width: "45%",
                height: 50,
                backgroundColor: "background.main",
              }}
              defaultValue={appointmentEncounterTypes.indexOf(
                "Initial contact"
              )}
              onChange={(e) => {
                setEncounterType(appointmentEncounterTypes[e.target.value]);
              }}
            >
              {appointmentEncounterTypes.map((type, index) => (
                <MenuItem value={index}>{type}</MenuItem>
              ))}
            </Select>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Box sx={{ flex: 1 }}>
                <Typography sx={{ marginTop: 3 }} variant="body1">
                  Date of Event [Optional]
                </Typography>{" "}
                {/* Update Date here*/}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{
                      width: "90%",
                    }}
                    components={["DatePicker"]}
                  >
                    <DatePicker
                      // label="Basic date picker"
                      onChange={(newValue) => {
                        setEncounterDate(newValue);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography sx={{ marginTop: 3 }} variant="body1">
                  Encounter Start Time
                </Typography>{" "}
                {/* Update Date here*/}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker", "TimePicker"]}>
                    <TimePicker
                      // label="Controlled picker"
                      value={encounterStartTime}
                      onChange={(newValue) => {
                        setEncounterStartTime(newValue);
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Box>
            </Box>
            {/* <Typography sx={{ marginTop: 3 }} variant="body1">
                                Description
                            </Typography>{" "} */}
            {/* Update Date here*/}
            <TextField
              rows={5}
              sx={{
                marginTop: "3rem",
                width: "100%",
              }}
              value={noteText}
              placeholder=""
              multiline
              onChange={(e) => setNoteText(e.target.value)}
            />
          </Box>
          <Box sx={{ margin: "1.5rem 0", float: "right" }}>
            <Button
              onClick={() => navigation("/notes")}
              variant="outlined"
              sx={{
                margin: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "coloredText.main",
                color: "coloredText.main",
              }}
            >
              CANCEL
            </Button>
            <Button
              onClick={() => {
                (async () => {
                  if (!noteText.trim().length) {
                    alert("Note text cannot be empty!");
                  } else {
                    setLoading(true);
                    await APIEvent.post("v1/note", {
                      noteType: encounterType,
                      eventDate: `${encounterDate.format(
                        "YYYY-MM-DD"
                      )}T${encounterStartTime.format("HH:mm:ss")}`,
                      participant: {
                        id: selectedParticipant,
                      },
                      description: noteText,
                      //noteCreationDate: new Date(),
                    });
                    await fetchData(
                      setParticipants,
                      setError,
                      setSelectedParticipant
                    );
                    alert("Note added successfully!");
                  }
                  setNoteText("");
                  setEncounterType("Initial contact");
                  setEncounterDate(dayjs());
                  setEncounterStartTime(dayjs());
                  setSelectedParticipant(participants[0].id);
                  navigation("/notes");
                })();
              }}
              variant="contained"
              sx={{
                margin: "10px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "coloredText.main",
                color: "white",
              }}
            >
              ADD NOTE
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
