import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  TextInput,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import axios, { all } from "axios";
import { useKeycloak } from "@react-keycloak/web";

import APIParticipant from "../APIParticipant";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

export default function AddParticipant({
  type,
  close,
  fetchData,
  editingData,
}) {
  const [participantAge, setParticipantAge] = useState(0);
  const [newParticipant, setNewParticipant] = useState("");
  const [appleId, setAppleId] = useState("");
  const [chnId, setChnId] = useState(editingData ? editingData.chnId : "");
  const [zoomLink, setZoomLink] = useState(
    editingData ? editingData.zoomLink : ""
  );
  const [navListData, setNavListData] = useState([]);
  const { keycloak } = useKeycloak();

  const [ageValueError, setAgeValueError] = useState(false);
  const [ageProvidedError, setAgeProvidedError] = useState(false);
  const [idProvidedError, setIdProvidedError] = useState(false);
  const [idSpaceError, setIdSpaceError] = useState(false);
  const [idSpecialCharacterError, setIdSpecialCharacterError] = useState(false);
  const [CHNidProvidedError, setCHNidProvidedError] = useState(false);
  const [appleIdProvidedError, setAppleIdProvidedError] = useState(false);
  const [appleIdSpaceError, setAppleIdSpaceError] = useState(false);
  const [appleIdAtError, setAppleIdAtError] = useState(false);
  const [zoomLinkProvidedError, setZoomLinkProvidedError] = useState(false);
  const [zoomLinkSpaceError, setZoomLinkSpaceError] = useState(false);
  const [CHNidSpaceError, setCHNidSpaceError] = useState(false);
  const [CHNidSpecialCharacterError, setCHNidSpecialCharacterError] =
    useState(false);

  useEffect(() => {
    const fetchNavList = async () => {
      try {
        const { data } = await APIParticipant.get(
          "/v1/api/navigator"
        );
        // console.log("data here: ", data.data );
        setNavListData(data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchNavList();
  }, [keycloak]);

  const participantText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Participant ID
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          placeholder=""
          onChange={(e) => {
            setIdSpaceError(e.target.value && e.target.value.includes(" "));

            setNewParticipant(e.target.value);
          }}
          helperText={
            idProvidedError
              ? "Participant ID is required"
              : idSpaceError
              ? "Participant ID cannot contain spaces"
              : idSpecialCharacterError
              ? "Participant ID cannot contain special characters"
              : ""
          }
          error={idProvidedError || idSpaceError || idSpecialCharacterError}
        />
      </>
    );
  };

  const participantAgeText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Age
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          placeholder=""
          inputProps={{ min: 0, max: 120 }}
          onChange={(e) => {
            setAgeValueError(
              e.target.value &&
                (parseInt(e.target.value) < 1 ||
                  isNaN(parseInt(e.target.value)))
            );
            setParticipantAge(e.target.value);
          }}
          helperText={
            ageValueError
              ? "Age must be a positive number"
              : ageProvidedError
              ? "Age is required"
              : ""
          }
          error={ageValueError || ageProvidedError}
        />
      </>
    );
  };

  const chnIdText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          CHN ID
        </Typography>
        <Select
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: "background.main",
          }}
          value={chnId}
          label="Select Participant"
          helperText={CHNidProvidedError ? "CHN ID is required" : ""}
          error={CHNidProvidedError}
          onChange={(e) => {
            setCHNidSpaceError(e.target.value && e.target.value.includes(" "));
            setChnId(e.target.value);
          }} //setParticipant
        >
          {/* <MenuItem value={0}>Select Participant</MenuItem> */}
          {navListData.map((nav) => (
            <MenuItem value={nav.id} key={nav.id}>
              {nav.username}
            </MenuItem>
          ))}
        </Select>
      </>
    );
  };

  const appleIdText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Apple ID
        </Typography>
        <TextField
          rows={1}
          type="email"
          sx={{
            width: "100%",
          }}
          placeholder=""
          onChange={(e) => {
            setAppleIdSpaceError(
              e.target.value && e.target.value.includes(" ")
            );
            setAppleId(e.target.value);
          }}
          helperText={
            appleIdProvidedError
              ? "Apple ID is required"
              : appleIdSpaceError
              ? "Apple ID cannot contain spaces"
              : appleIdAtError
              ? "Apple ID must include @"
              : ""
          }
          error={appleIdProvidedError || appleIdSpaceError || appleIdAtError}
        />
      </>
    );
  };

  const zoomText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Zoom Link
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          placeholder=""
          onChange={(newValue) => setZoomLink(newValue.target.value)}
          helperText={
            zoomLinkProvidedError
              ? "Zoom Link is required"
              : zoomLinkSpaceError
              ? "Zoom Link cannot contain spaces"
              : ""
          }
          error={zoomLinkProvidedError || zoomLinkSpaceError}
        />
      </>
    );
  };

  function clearAll() {
    setParticipantAge();
    setNewParticipant("");
    setAppleId("");
    setChnId("");
    setZoomLink("");
  }

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        Add Participant
      </Typography>

      {participantText()}
      {participantAgeText()}
      {chnIdText()}
      {appleIdText()}
      {zoomText()}

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            (async () => {
              if (!participantAge) setAgeProvidedError(true);
              if (!newParticipant.trim().length) setIdProvidedError(true);
              if (newParticipant.includes(" ")) setIdSpaceError(true);
              if (!alphanumeric.test(newParticipant))
                setIdSpecialCharacterError(true);
              if (!chnId.trim().length) setCHNidProvidedError(true);

              if (!zoomLink.trim().length) setZoomLinkProvidedError(true);
              if (zoomLink.includes(" ")) setZoomLinkSpaceError(true);
              if (!appleId.trim().length) setAppleIdProvidedError(true);
              if (!appleId.includes("@")) setAppleIdAtError(true);

              //checks age is not e - research why it lets me put in e

              try {
                await APIParticipant.post(
                  "/v1/api/patient",
                  {
                    age: parseInt(participantAge),
                    zoomURL: zoomLink,
                    appleId: appleId,
                    navigatorId: chnId,
                    participantId: newParticipant,
                  }
                );
                // await axios({
                //   method: "POST",
                //   url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/patient/`,
                //   headers: {
                //     Authorization: `Bearer ${keycloak.token}`,
                //   },
                //   data: {
                //     age: parseInt(participantAge),
                //     zoomURL: zoomLink,
                //     appleId: appleId,
                //     navigatorId: chnId,
                //     participantId: newParticipant,
                //   },
                // });
                close();
                await fetchData();
              } catch (error) {
                alert(error?.response?.data);
              }
            })();
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          Add Participant
        </Button>
      </Box>
    </Box>
  );
}
