import React, { useState, useEffect } from "react";
import { LoadingScreen, Error } from "../components";
import { Box, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useKeycloak } from "@react-keycloak/web";
import { useParams } from "react-router-dom";
import EventCard from "../components/EventCard";
import APIEvent from "../APIEvent";

const fetchEventData = async (
  setLoading,
  setToDos,
  setMeetings,
  setNotes,
  id
) => {
  //fetch function, for reuse when database changes. Because setters are defined in a different scope they need to be passed in.
  //Defining this outside the component definition improves performance and avoids unnecessary rerenders
  try {
    let toDoData = (await APIEvent.get("/v1/event/Todo")).data;
    console.log(toDoData);
    setToDos(toDoData.filter((item) => item.patient.id === id));

    let meetingData = (await APIEvent.get("/v1/event/Meeting")).data;
    console.log(meetingData);
    setMeetings(meetingData.filter((item) => item.patient.id === id));

    let noteData = (
      await APIEvent.get(
        "/v1/note/navigator/c34c2151-ebbc-4a0e-a832-2bd319449724"
      )
    ).data;
    console.log(noteData);
    setNotes(noteData.filter((item) => item.participant.id === id));
  } catch (error) {
    console.log(error);
  }
  setLoading(false);
};

const handleRemove = async (id, setToDos) => {
  console.log(id);
};

export default function ParticipantPage() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toDos, setToDos] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [notes, setNotes] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchEventData(setLoading, setError, setToDos, setMeetings, setNotes, id);
  }, [id]);

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {console.log(toDos)}
        {console.log(meetings)}
        {console.log(notes)}
        <Typography variant="h4" sx={{ mt: 2, mb: 2 }}>
          Participant {id}
        </Typography>
        {toDos.map((item) => (
          <EventCard key={item.id} event={item} handleRemove={handleRemove} />
        ))}
      </Box>
    </div>
  );
}
