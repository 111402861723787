import React from "react";
import AddParticipant from "./AddParticipant";
import AddNavigator from "./AddNavigator";
import EditParticipant from "./EditParticipant";
import EditNavigator from "./EditNavigator";
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import AddToDo from "./AddToDo";
import EditToDo from "./EditToDo";

export default function OverlayPicker({
  type,
  editOverlayData,
  close,
  setModalData,
  fetchData,
  data,
}) {
  if (type === "AddParticipant") {
    return <AddParticipant fetchData={fetchData} close={close} />;
  } else if (type === "AddNavigator") {
    return <AddNavigator close={close} fetchData={fetchData} />;
  } else if (type === "EditParticipant") {
    return (
      <EditParticipant
        setModalData={setModalData}
        editOverlayData={editOverlayData}
        fetchData={fetchData}
        close={close}
      />
    );
  } else if (type === "EditNavigator") {
    return (
      <EditNavigator
        setModalData={setModalData}
        editOverlayData={editOverlayData}
        fetchData={fetchData}
        close={close}
        data={data}
      />
    );
  } else if (type === "AddEvent") {
    return <AddEvent close={close} fetchData={fetchData} />;
  } else if (type === "EditEvent") {
    return (
      <EditEvent
        close={close}
        fetchData={fetchData}
        editOverlayData={editOverlayData}
      />
    );
  } else if (type === "AddToDo") {
    return <AddToDo close={close} fetchData={fetchData} />;
  } else if (type === "EditToDo") {
    return (
      <EditToDo
        close={close}
        fetchData={fetchData}
        editOverlayData={editOverlayData}
      />
    );
  } else {
    return (
      <div>
        <p>THere is a problem with the overlay picker</p>
      </div>
    );
  }
}
