import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import ColorModeContext from "./context/ColorModeContext";
import {
  Login,
  DashboardPage,
  CalendarPage,
  LearnPage,
  SchedulePage,
  ParticipantList,
  NotesPage,
  AddNotesPage,
  AppendNotesPage,
  ResourcesPage,
  VideoSelectionPage,
  VideoPlayer,
  AssessmentsPage,
  TestCalendar,
  AdminPage,
  ParticipantPage,
  ViewNotePage,
  Sandbox, //dev testing page for smaller components, delete on final build
} from "./pages";
import { LoadingScreen, Error } from "./components";
import { NavBar } from "./containers";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  responsiveFontSizes,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useKeycloak } from "@react-keycloak/web";
import { atom } from "jotai";

//TODO: put all global states here

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    primary: { main: "#0F4D81" },
    secondary: {
      main: "#EFCA77",
      light: "#FFF3CF",
    },
    error: {
      main: "#C2185B",
    },
    warning: {
      main: "#E89923",
    },
    info: {
      main: "#0577B1",
    },
    success: {
      main: "#12873e",
    },
    background:
      mode === "light"
        ? {
            paper: "#EDEBEB",
            cloud: "#E2E6ED",
            main: "#FFFFFF",
            notification: "#E5E5E5",
            notificationWarning: "#F8E8D1",
            notificationOverdue: "#F2D9CA",
          }
        : {
            main: "#262626",
            paper: "#433c3c",
            cloud: "#354156",
            notification: "#b7b7b7",
            notificationWarning: "#ecc082",
            notificationOverdue: "#e1a683",
          },
    icons: mode === "light" ? { main: "#0F4D81" } : { main: "#84c2f1" },
    defaultText: mode === "light" ? { main: "#474747" } : { main: "#FFF" },
    coloredText: mode === "light" ? { main: "#0F4D81" } : { main: "#84c2f1" },
  },
  slider: {
    trackColor: "yellow",
    selectionColor: "red",
  },
  typography: {
    fontFamily: "Montserrat",
    allVariants: {
      color: mode === "light" ? "#0F4D81" : "#84c2f1",
    },
    nav: {
      fontSize: 16,
      fontWeight: "600",
    },
    h1: {
      fontSize: 96,
      fontWeight: 600,
    },
    h2: {
      fontSize: 64,
      fontWeight: 700,
    },
    h3: {
      fontSize: 48,
      fontWeight: 700,
    },
    h4: {
      fontSize: 36,
      fontWeight: 700,
    },
    h5: {
      fontSize: 24,
      fontWeight: 700,
    },
    h6: {
      fontSize: 20,
      fontWeight: 700,
    },
    video: {
      fontSize: 16,
      color: "white",
    },
    subtitle1: {
      fontSize: 16, //tweaked for adherence to style guide
      fontWeight: 400, // ^^^
      padding: "5px 0",
    },
    subtitle2: {
      fontSize: 16, //tweaked for adherence to style guide
      fontWeight: 600, // ^^^
      padding: "5px 0",
    },
    overline1: {
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
    overline2: {
      fontSize: 14,
      fontWeight: 700,
      fontFamily: "Montserrat",
    },
    body1: {
      // lineHeight: "1.4rem", //had to comment out for site to load, MUI is mad at rem
      // padding: "5px 0",
      fontSize: 18, //added for adherence with style guide, see "body"
      fontWeight: 400, // ^^^
      fontFamily: "Montserrat",
    },
    transcript: {
      // lineHeight: "1.4rem", //had to comment out for site to load, MUI is mad at rem
      fontSize: "12pt",
    },
    button: {
      // fontStyle: "italic", removed for adherence with style guide
      cursor: "pointer",
      fontSize: 16,
      fontWeight: 600,
    },
    backButton: {
      cursor: "pointer",
      color: "#0f4d81",
      backgroundColor: "white",
      fontWeight: "bold",
      padding: "10px 0",
    },
    caption: {
      fontSize: 14,
      fontWeight: 400,
    },
  },
});

function App() {
  const { keycloak } = useKeycloak();
  const [mode, setMode] = useState("light");
  const colorMode = useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  // useEffect(() => {
  //     const handleTabClose = (event) => {
  //         event.preventDefault();
  //         keycloak.logout();
  //         console.log("tab closed, logged out");
  //         event.returnValue = "";
  //     };

  //     window.addEventListener("beforeunload", handleTabClose);

  //     return () => {
  //         window.removeEventListener("beforeunload", handleTabClose);
  //     };
  // }, [keycloak]);

  let theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  theme = responsiveFontSizes(theme);

  return (
    <>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            {!keycloak.authenticated ? (
              <LoadingScreen />
            ) : (
              <>
                <NavBar />
                <Routes>
                  {/* <Route path="/login" element={<Login />} /> */}
                  <Route
                    path="/"
                    element={
                      keycloak.tokenParsed.roles.includes("Admin") ? (
                        <AdminPage />
                      ) : (
                        <DashboardPage />
                      )
                    }
                  />

                  {!keycloak.tokenParsed.roles.includes("Admin") && (
                    <>
                      <Route path="/learn" element={<LearnPage />} />
                      <Route
                        path="/learn/course/:id"
                        element={<VideoPlayer />}
                      />

                      <Route path="/calendar" element={<CalendarPage />} />

                      <Route
                        path="/participants"
                        element={<ParticipantList />}
                      />
                      <Route path="/notes" element={<NotesPage />} />
                      <Route path="/addNote" element={<AddNotesPage />} />
                      <Route
                        path="/appendNote/:id"
                        element={<AppendNotesPage />}
                      />
                      <Route path="/viewNote/:id" element={<ViewNotePage />} />
                      <Route
                        path="/participants/:id"
                        element={<ParticipantPage />}
                      />
                    </>
                  )}

                  {/* <Route path="sandbox" element={<Sandbox />} /> */}

                  <Route
                    path="*"
                    element={<Error code="404" reason="Not Found" />}
                  />
                </Routes>
              </>
            )}
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </>
  );
}

export default App;
