import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Card,
  CardActionArea,
  Typography,
  Box,
  useTheme,
  CircularProgress,
  sizing,
  Select,
  MenuItem,
  Button,
  CardContent,
  InputBase,
  IconButton,
  CardActions,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Search,
  PersonOutline,
} from "@mui/icons-material";
// import SearchIcon from '@mui/icons-material/Search';
import { currentPage } from "./currentPage";

import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import dayjs from "dayjs";
import { LoadingScreen, Error, ParticipantCard } from "../components";
import APIParticipant from "../APIParticipant";

const seeParticipants = async (data) => {
  try {
    for (let participant of data) {
      console.log(participant.id);
      await APIParticipant.patch(
        `/v1/api/patientnavigator/patient/${participant.id}`
      );
    }
    // await axios({
    //   method: "patch",
    //   url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/patientnavigator/patient/${id}`,
    //   headers: {
    //     Authorization: `Bearer ${keycloak.token}`,
    //   },
    // });
  } catch (error) {
    console.log(error);
  }
};

export default function ParticipantList() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [viewingParticipant, setViewingParticipant] = useState(0);
  const [displayedParticipants, setDisplayedParticipants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const { keycloak } = useKeycloak();
  const navigation = useNavigate();
  currentPage("Participants");

  useEffect(() => {
    console.log("on load useEffect");
    const fetchData = async () => {
      try {
        const { data } = await APIParticipant.get("/v1/api/patient/");
        // console.log("data: ", data.data);
        setData((oldData) => data);
        setLoading(false);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchData();

    // console.log(data);
    // data.forEach((participant) => seeParticipants(participant.id));
  }, []);

  useEffect(() => {
    //does appropriate filtering for each view as participant and date changes
    if (data) {
      setDisplayedParticipants(
        data.filter(
          (event) =>
            !viewingParticipant || event.participantId === viewingParticipant
        )
      );

      seeParticipants(data);
    }
  }, [viewingParticipant, data]);

  if (loading) return <LoadingScreen />;
  if (error) return <Error code={error.code} reason={error.reason} />;

  return (
    <div>
      <Container maxWidth="md" sx={{ minWidth: "sm" }}>
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "153px",
              left: 0,
              width: "100%",
              backgroundColor: "background.main",
            }}
          >
            <Box
              sx={{
                padding: "2.5rem 5rem",
              }}
            >
              <button onClick={() => navigation("/")}>
                <Typography variant="backButton">
                  {"<< BACK TO DASHBOARD"}
                </Typography>
              </button>
              <Typography
                sx={{
                  lineHeight: 1.3,
                  padding: "0.8rem 0",
                }}
                variant="h2"
              >
                Participants
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "2rem 5rem",
              }}
            >
              <Box>
                <Typography sx={{ marginTop: 3 }} variant="body1">
                  View Participants:
                </Typography>
                {/* Update Date here*/}
                <Select
                  sx={{
                    width: 250,
                    height: 50,
                    marginBottom: "2rem",
                    backgroundColor: "background.main",
                  }}
                  value={viewingParticipant}
                  label="View Events for Participant with ID"
                  onChange={(e) => setViewingParticipant(e.target.value)}
                >
                  <MenuItem value={0}>All Participants</MenuItem>
                  {[...new Set(data.map((event) => event.participantId))].map(
                    (id) => (
                      <MenuItem value={id} key={id}>
                        {id}
                      </MenuItem>
                    )
                  )}
                </Select>
                {displayedParticipants.map((participant) => (
                  <ParticipantCard
                    key={participant.id}
                    page={"participant"}
                    id={participant.id}
                    participantId={participant.participantId}
                    age={participant.age}
                    zoomURL={participant.zoomURL}
                    appleId={participant.appleId}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        )}
      </Container>
    </div>
  );
}
