import React, { useState, useEffect } from "react";
import { currentPage } from "../currentPage";
import dayjs from "dayjs";

import APIEvent from "../../APIEvent";

// components
import {
  NeedHelp,
  Recommended,
  VideoList,
  GettingStarted,
  LoadingScreen,
  AddOverlay,
  EventCard,
  OverlayPicker,
} from "../../components";

import {
  Typography,
  Container,
  Box,
  CircularProgress,
  Tabs,
  Tab,
  Select,
  MenuItem,
  Button,
  Card,
  CardActions,
  CardContent,
  Backdrop,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  ExpandMore,
  ExpandLess,
  PersonOutline,
  WarningAmber,
  CheckCircleOutline,
} from "@mui/icons-material";
import { Info } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { set } from "date-fns";
import {
  DatePicker,
  LocalizationProvider,
  AdapterDayjs,
} from "@mui/x-date-pickers";
import keycloak from "../../keycloak";
import AddEvent from "../../components/AddEvent";
import APIParticipant from "../../APIParticipant";

// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// ----------------------------------------------------------------------

const fetchEventData = async (
  setEvents,
  setLoading,
  setToDoData,
  setComingUpToDos,
  setOverdueToDos
) => {
  //fetch function, for reuse when database changes. Because setters are defined in a different scope they need to be passed in.
  //Defining this outside the component definition improves performance and avoids unnecessary rerenders
  setLoading(true);
  try {
    const { data } = await APIEvent.get("v1/event/Meeting");
    console.log(data);

    setEvents(data);

    const toDo = await APIEvent.get("v1/event/Todo");

    setToDoData(toDo.data);
    setComingUpToDos(toDo.data.filter((toDo) => toDo.status === "OVERDUE"));
    setOverdueToDos(toDo.data.filter((toDo) => toDo.status === "PAST_OVERDUE"));
  } catch (error) {
    console.log(error);
  }

  setLoading(false);
};

export default function DashboardPage() {
  const theme = useTheme();
  currentPage("Dashboard");
  const navigation = useNavigate();
  const { keycloak } = useKeycloak();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allParticipants, setAllParticipants] = useState([]);

  const [toDoData, setToDoData] = useState([]);

  const [date, setDate] = useState(dayjs());
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [viewingParticipant, setViewingParticipant] = useState(0);
  const [viewingToDos, setViewingToDos] = useState("TODO");
  const [showingToDos, setShowingToDos] = useState("ALL");
  const [sortingToDos, setSortingToDos] = useState("DATE");
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [displayedToDos, setDisplayedToDos] = useState([]);
  const [newToDos, setNewToDos] = useState([]);
  const [comingUpToDos, setComingUpToDos] = useState([]);
  const [overdueToDos, setOverdueToDos] = useState([]);
  const [newParticipants, setNewParticipants] = useState(10);
  // const [value, setValue] = React.useState('1');
  const [editingID, setEditingID] = useState(-1);
  const [tabState, setTabState] = useState("1");

  const [overlayType, setOverlayType] = useState("");
  const [editOverlayData, setEditOverlayData] = useState({});

  const handleTabs = (value) => {
    setTabState(value);
    return;
  };

  const handleEdit = (id) => {
    return () => {
      setEditingID(id);
    };
  };

  const [open, setOpen] = useState(false);
  const closeAddApp = () => {
    setOpen(false);
    setOverlayType("");
  };
  const openAddApp = () => {
    setOpen(true);
  };

  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  useEffect(() => {
    console.log("editOverlayData: ", editOverlayData);
    console.log("overlayType: ", overlayType);
  }, [editOverlayData, overlayType]);

  useEffect(() => {
    console.log("on load useEffect");
    console.log("keycloak object: ", keycloak);
    fetchEventData(
      setData,
      setLoading,
      setToDoData,
      setComingUpToDos,
      setOverdueToDos
    );
  }, [keycloak.token, keycloak]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await APIParticipant.get("/v1/api/patientnavigator/new");
        console.log(data);

        setNewParticipants(data.length);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    console.log("on load useEffect");
    const fetchParticipantData = async () => {
      try {
        const { data } = await APIParticipant.get(
          "/v1/api/patient/"
        );
        console.log("data: ", data);
        setAllParticipants(data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    fetchParticipantData();

    // console.log(data);
    // data.forEach((participant) => seeParticipants(participant.id));
  }, []);

  useEffect(() => {
    toDoData.forEach((toDo) => {
      if (toDo.isNew) {
        seeToDo(toDo.id);
      }
    });
  }, [tabState]);

  useEffect(() => {
    //does appropriate filtering for each view as participant and date changes
    if (data) {
      setDisplayedEvents(
        data.filter(
          (event) =>
            !viewingParticipant ||
            event.patient.participantId === viewingParticipant
        )
      );

      if (tabState === "1") {
        setDisplayedEvents((display) =>
          display
            .filter((event) => dayjs(event.start).isSame(date, "day"))
            .sort((firstEvent, secondEvent) =>
              dayjs(firstEvent.start).isSame(secondEvent.start, "minute")
                ? 0
                : dayjs(firstEvent.start).isBefore(secondEvent.start, "minute")
                ? -1
                : 1
            )
        );
      }
    }

    //Shows the different types of to do / done
    if (toDoData) {
      setNewToDos(toDoData.filter((todo) => todo.isNew === true));
      if (viewingToDos === "TODO") {
        setDisplayedToDos(
          toDoData.filter(
            (toDo) =>
              // console.log(toDo.status + " :to do: " + viewingToDos)
              !viewingToDos ||
              toDo.status === "TODO" ||
              toDo.status === "PAST_OVERDUE" ||
              toDo.status === "OVERDUE"
          )
        );
      }
      if (viewingToDos === "DONE") {
        setDisplayedToDos(
          toDoData.filter(
            (toDo) => !viewingToDos || toDo.status === viewingToDos
          )
        );
      }
      if (showingToDos === "ALL") {
        if (viewingToDos === "TODO") {
          setViewingToDos("TODO");
        }
      } else {
        setDisplayedToDos(
          toDoData.filter(
            (toDo) => !showingToDos || toDo.status === showingToDos
          )
        );
      }

      if (sortingToDos === "DATE") {
        setDisplayedToDos((display) =>
          display.sort((a, b) => dayjs(a.end) - dayjs(b.end))
        );
      }
      if (sortingToDos === "NEW") {
        console.log("inside new");
        setDisplayedToDos((display) =>
          display.sort((a, b) => dayjs(a.dateCreated) - dayjs(b.dateCreated))
        );
      }
      if (sortingToDos === "OLD") {
        console.log("inside old");
        setDisplayedToDos((display) =>
          display.sort((a, b) => dayjs(b.dateCreated) - dayjs(a.dateCreated))
        );
      }
    }
  }, [
    date,
    viewingParticipant,
    data,
    toDoData,
    viewingToDos,
    sortingToDos,
    showingToDos,
    tabState,
  ]);

  useEffect(() => {
    fetchEventData(
      setData,
      setLoading,
      setToDoData,
      setComingUpToDos,
      setOverdueToDos
    );
  }, []);

  const seeToDo = async (id) => {
    try {
      console.log(`setting todo # ${id} to seen`);
      await APIEvent.put(`v1/event/${id}`, {
        id: id,
        isNew: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [devices, setDevices] = useState(["phone"]);
  const handleDevices = (event, newDevices) => {
    if (newDevices.length) {
      setDevices(newDevices);
    }
  };

  const handleEventObject = (eventObject) => {
    console.log(eventObject);
  };

  const handleRemove = (id) => {
    return async () => {
      console.log("removing");
      try {
        await APIEvent.delete(`v1/event/${id}`);
        await fetchEventData(
          setData,
          setLoading,
          setToDoData,
          setComingUpToDos,
          setOverdueToDos
        );
      } catch (error) {
        console.log(error);
      }
    };
  };

  const handleComplete = (id) => {
    return async () => {
      try {
        await APIEvent.patch(`v1/event/todo/status/${id}`);

        await fetchEventData(
          setData,
          setLoading,
          setToDoData,
          setComingUpToDos,
          setOverdueToDos
        );
      } catch (error) {
        console.log(error);
      }
    };
  };

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
      // maxWidth={false}
    >
      {/* <h1>Dashboard</h1> */}
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Box sx={{ display: "flex", paddingBottom: "2.5rem" }}>
          {newParticipants !== 0 && (
            <Box
              onClick={() => navigation("/participants")}
              sx={{
                backgroundColor: "background.notification",
                marginRight: "1rem",
                textAlign: "center",
                padding: "5px 20px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                component="span"
                sx={{
                  fontSize: "14px",
                  color: "#474747",
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
                variant="subtitle1"
              >
                New participant assigned
              </Typography>
              <PersonOutline />
              <Typography
                component="span"
                sx={{
                  fontSize: "14px",
                  backgroundColor: "gray",
                  color: "white",
                  padding: "0px 10px",
                  borderRadius: "15px",
                  marginLeft: "5px",
                }}
                variant="subtitle1"
              >
                {newParticipants}
              </Typography>
            </Box>
          )}

          <Box
            onClick={() => setTabState("2")}
            sx={{
              backgroundColor: "background.notification",
              marginRight: "1rem",
              textAlign: "center",
              padding: "5px 20px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#474747",
                marginRight: "5px",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              New to do items
            </Typography>
            <CheckCircleOutline />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                backgroundColor: "gray",
                color: "white",
                padding: "0px 10px",
                borderRadius: "15px",
                marginLeft: "5px",
              }}
              variant="subtitle1"
            >
              {newToDos.length}
            </Typography>
          </Box>

          <Box
            onClick={() => setTabState("2")}
            sx={{
              backgroundColor: "background.notificationWarning",
              marginRight: "1rem",
              textAlign: "center",
              padding: "5px 20px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#8C5D15",
                marginRight: "5px",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              To do coming up
            </Typography>
            <WarningAmber sx={{ color: "#8C5D15" }} />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                backgroundColor: "#8C5D15",
                color: "#F8E8D1",
                padding: "0px 10px",
                borderRadius: "15px",
                marginLeft: "5px",
              }}
              variant="subtitle1"
            >
              {comingUpToDos.length}
            </Typography>
          </Box>

          <Box
            onClick={() => setTabState("2")}
            sx={{
              backgroundColor: "background.notificationOverdue",
              marginRight: "1rem",
              textAlign: "center",
              padding: "5px 20px",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                color: "#9C3D00",
                marginRight: "5px",
                fontWeight: "bold",
              }}
              variant="subtitle1"
            >
              To do overdue
            </Typography>
            <WarningAmber sx={{ color: "#9C3D00" }} />
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                backgroundColor: "#9C3D00",
                color: "#F2D9CA",
                padding: "0px 10px",
                borderRadius: "15px",
                marginLeft: "5px",
              }}
              variant="subtitle1"
            >
              {overdueToDos.length}
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            lineHeight: 1.3,
            padding: "0.8rem 0",
          }}
          variant="h2"
        >
          Welcome back, {"\n"} {keycloak.tokenParsed.preferred_username}!
        </Typography>

        {tabState === "1" && (
          <Typography
            sx={{
              lineHeight: 1.3,
              padding: "0.8rem 0",
            }}
            variant="h4"
          >
            You have {displayedEvents.length} appointments today.
          </Typography>
        )}
        {/* <Typography variant="body1">
                    <Info sx={{ color: "#0F4D81" }} size={30} /> All assessments
                    must be completed before you begin navigation.
                </Typography> */}
      </Box>
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Box
          sx={
            {
              // display: "flex",
              // justifyContent: 'flex-start',
            }
          }
        >
          <TabContext value={tabState}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                value={tabState}
                onChange={(event, value) => {
                  handleTabs(value);
                }}
                textColor="Black"
                aria-label=""
              >
                <Tab label="Appointments" value="1" />
                <Tab label="To Do List" value="2" />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                padding: "0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "35px",
                }}
              >
                <DatePicker
                  open={isForcePickerOpen}
                  onClose={() => setIsOpen(false)}
                  components={{
                    OpenPickerIcon: "null",
                  }}
                  views={["day"]}
                  value={date}
                  onChange={setDate}
                  format="MMMM DD, YYYY"
                  sx={{
                    // margin: "10px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "0",
                      },
                      "& input": {
                        color: "coloredText.main",
                        width: "190px",
                        padding: "8px 10px 8px 0",
                        margin: "10px 0",
                      },
                    },
                  }}
                />
                <Button
                  variant="outlined"
                  sx={{
                    margin: "10px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                  }}
                  onClick={() => setIsOpen((isOpen) => !isOpen)}
                >
                  VIEW DATE
                </Button>
                <Button
                  onClick={() => navigation("/calendar")}
                  variant="outlined"
                  sx={{
                    margin: "10px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                  }}
                >
                  VIEW CALENDAR
                </Button>
                <Button
                  variant="contained"
                  // I need a state that is the overlay type and i already have that in the admin page, is there some reason I shouldnt just set it up the same way here
                  onClick={() => {
                    setOverlayType("AddEvent");
                    setEditingID(-1);
                    openAddApp();
                  }}
                  sx={{
                    margin: "10px",
                    marginLeft: "auto",
                  }}
                >
                  ADD APPOINTMENT +{" "}
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <Box
                    sx={{
                      padding: "2.5rem 5rem",
                      backgroundColor: "background.main",
                      width: "70%",
                      height: "70%",
                      borderRadius: "15px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    <OverlayPicker
                      type={overlayType}
                      editingID={editingID}
                      editOverlayData={editOverlayData}
                      name="Appointment"
                      // event={{
                      //   id: 1,
                      //   title: "text",
                      //   start_end: "dropdown",
                      //   date: "Encounter",
                      //   editable: "true",
                      //   category: "dropdown",
                      //   // description: "text",
                      //   participant: "dropdown",
                      // }}
                      fetchData={async () => {
                        fetchEventData(
                          setData,
                          setLoading,
                          setToDoData,
                          setComingUpToDos,
                          setOverdueToDos
                        );
                      }}
                      close={closeAddApp}
                    />
                  </Box>
                </Backdrop>
              </Box>
              <Box>
                <Typography sx={{ marginTop: 3 }} variant="body1">
                  View Appointments by Participant:
                </Typography>
                {/* Update Date here*/}
                <Select
                  sx={{
                    width: 320,
                    height: 50,
                    backgroundColor: "background.main",
                  }}
                  value={viewingParticipant}
                  label="View Events for Participant with ID"
                  onChange={(e) => setViewingParticipant(e.target.value)}
                >
                  <MenuItem value={0}>All Participants</MenuItem>
                  {allParticipants.map((participant) => (
                    <MenuItem
                      value={participant.participantId}
                      key={participant.id}
                    >
                      {participant.participantId}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  marginTop: "35px",
                }}
              >
                {displayedEvents.length ? (
                  displayedEvents.map((event) => (
                    <EventCard
                      setModalData={setEditOverlayData}
                      setOverlayType={setOverlayType}
                      handleEdit={handleEdit(event.id)}
                      key={event.id}
                      event={event}
                      handleRemove={handleRemove(event.id)}
                      openAddApp={openAddApp}
                      editOverlayData={editOverlayData}
                    />
                  ))
                ) : (
                  <Typography
                    variant="h2"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    Nothing Today!
                  </Typography>
                )}
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                padding: "0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "35px",
                }}
              >
                {/* Change into tab system */}
                <Button
                  variant={viewingToDos === "TODO" ? "contained" : "outlined"}
                  // variant="outlined"
                  sx={{
                    margin: "10px 10px 10px 0",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "coloredText.main",
                    color:
                      '{viewingToDos === "TODO" ? "defaultText.main" : "coloredText.main"}',
                  }}
                  onClick={() => setViewingToDos("TODO")}
                >
                  TO DO
                </Button>
                <Button
                  variant={viewingToDos === "DONE" ? "contained" : "outlined"}
                  // color={viewingToDos === "DONE" ? "defaultText.main" : "coloredText.main"}
                  sx={{
                    margin: "10px",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: "coloredText.main",
                    color:
                      '{viewingToDos === "DONE" ? "defaultText.main" : "coloredText.main"}',
                  }}
                  // onClick={setViewingToDos("DONE")}
                  onClick={() => setViewingToDos("DONE")}
                >
                  DONE
                </Button>
                {/* <Button variant="contained" sx={{ margin: "10px" }}>
                  BOTH{" "}
                </Button> */}
                <Button
                  onClick={() => (
                    setEditingID(-1), setOverlayType("AddToDo"), openAddApp()
                  )}
                  variant="contained"
                  sx={{ margin: "10px", marginLeft: "auto" }}
                >
                  ADD TO DO +
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                >
                  <Box
                    sx={{
                      padding: "2.5rem 5rem",
                      backgroundColor: "background.main",
                      width: "70%",
                      height: "70%",
                      borderRadius: "15px",
                      overflow: "hidden",
                      overflowY: "scroll",
                    }}
                  >
                    {/* <AddOverlay name="Task"/>  */}
                    <OverlayPicker
                      editOverlayData={editOverlayData}
                      type={overlayType}
                      editingID={editingID}
                      name="To Do"
                      event={{
                        id: 1,
                        title: "text",
                        // start_end: "dropdown",
                        date: "Due",
                        editable: "true",
                        category: "dropdown",
                        description: "text",
                        participant: "dropdown",
                      }}
                      fetchData={async () => {
                        fetchEventData(
                          setData,
                          setLoading,
                          setToDoData,
                          setComingUpToDos,
                          setOverdueToDos
                        );
                      }}
                      close={closeAddApp}
                    />
                  </Box>
                </Backdrop>
              </Box>
              <Box
                sx={{
                  marginTop: "25px",
                }}
              >
                <Typography variant="body1">Display By:</Typography>
                {/* Update Date here*/}
                <Select
                  sx={{
                    width: 250,
                    height: 50,
                    backgroundColor: "background.main",
                  }}
                  defaultValue={1}
                >
                  <MenuItem
                    onClick={() => (
                      setSortingToDos("DATE"), setShowingToDos("ALL")
                    )}
                    value={1}
                  >
                    Due Date
                  </MenuItem>
                  <MenuItem
                    onClick={() => (
                      setSortingToDos("NEW"), setShowingToDos("ALL")
                    )}
                    value={2}
                  >
                    Newest
                  </MenuItem>
                  <MenuItem
                    onClick={() => (
                      setSortingToDos("OLD"), setShowingToDos("ALL")
                    )}
                    value={3}
                  >
                    Oldest
                  </MenuItem>
                  <MenuItem
                    onClick={() => setShowingToDos("OVERDUE")}
                    value={4}
                  >
                    Due Soon
                  </MenuItem>
                  <MenuItem
                    onClick={() => setShowingToDos("PAST_OVERDUE")}
                    value={5}
                  >
                    Overdue
                  </MenuItem>
                </Select>
              </Box>
              <Box
                sx={{
                  marginTop: "35px",
                }}
              >
                {displayedToDos.length ? (
                  displayedToDos.map((toDo) => (
                    <EventCard
                      setModalData={setEditOverlayData}
                      setOverlayType={setOverlayType}
                      openAddApp={openAddApp}
                      handleEdit={handleEdit(toDo.id)}
                      key={toDo.id}
                      handleComplete={handleComplete(toDo.id)}
                      event={toDo}
                      todo
                    />
                  ))
                ) : (
                  <Typography
                    variant="h2"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    No To Dos Found
                  </Typography>
                )}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}
