import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Card,
  CardActionArea,
  Typography,
  Box,
  useTheme,
  CircularProgress,
  sizing,
  Select,
  MenuItem,
  Button,
  CardContent,
  Collapse,
  InputBase,
  IconButton,
  CardActions,
  Zoom,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Search,
  PersonOutline,
  VideoChat,
} from "@mui/icons-material";

import { currentPage } from "../pages/currentPage";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";
import dayjs from "dayjs";
import { LoadingScreen, Error } from ".";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  Edit,
  MailOutline,
  Visibility,
  VisibilityOff,
  DeleteOutline,
} from "@mui/icons-material";
// import EditIcon from "@mui/icons-material/Edit";
// import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { set } from "date-fns";

export default function ParticipantCard({
  age,
  zoomURL,
  appleId,
  CHNusername,
  username,
  participantId,
  page,
  handleRemove,
  openAddApp,
  id,
  NavAppleId,
  setModalData,
  setOverlayType,
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClick = () => {
    setModalData({
      id: id,
      age: age,
      zoomURL: zoomURL,
      appleId: appleId,
      CHNusername: CHNusername,
      participantId: participantId,
    });
    setOverlayType("EditParticipant");
    openAddApp();
  };

  return (
    <div>
      <Card
        sx={{
          minWidth: 275,
          padding: "5px",
          marginBottom: "25px",
          borderRadius: "15px",
          backgroundColor: "background.main",
          boxShadow: 5,
          maxWidth: "1000px",
        }}
      >
        <CardContent>
          {page === "admin" || page === "navigator" ? (
            <Box sx={{ float: "right" }}>
              {page === "admin" ? (
                <Button
                  sx={{
                    marginRight: "1rem",
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                  }}
                  variant="outlined"
                  size="small"
                  onClick={handleClick}
                >
                  <Edit fontSize="small" />
                  Edit
                </Button>
              ) : (
                <></>
              )}
              {page === "navigator" && (
                <Button
                  sx={{
                    borderColor: "coloredText.main",
                    color: "coloredText.main",
                    marginRight: "1rem",
                  }}
                  onClick={() => {
                    setOverlayType("EditNavigator");
                    // handleResetPassword();
                    setModalData({
                      CHNusername: username,
                    });
                    openAddApp();
                  }}
                  variant="outlined"
                  size="small"
                >
                  Reset Password
                </Button>
              )}
              <Button
                sx={{
                  borderColor: "coloredText.main",
                  color: "coloredText.main",
                }}
                variant="outlined"
                onClick={handleRemove}
                size="small"
              >
                <DeleteOutline />
                Remove
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {/* <Button
                component={Link}
                to={`/participant/${id}`}
                variant="outlined"
                sx={{
                  marginLeft: "auto",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "coloredText.main",
                  color: "coloredText.main",
                }}
              >
                VIEW PARTICIPANT <PersonOutline />
              </Button> */}
            </Box>
          )}
          {/* Particpant Page  */}
          {page === "admin" && (
            <Box>
              <Typography sx={{ marginBottom: 3 }} variant="h5">
                {participantId}
              </Typography>
              <Box>
                <Collapse
                  in={expanded}
                  variant="outlined"
                  timeout="auto"
                  unmountOnExit
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      Age:
                    </Typography>
                    <Typography
                      component="span"
                      sx={{ display: "inline" }}
                      variant="subtitle1"
                      color="defaultText.main"
                    >
                      &nbsp; {age}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      // component="span"
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      Apple ID:
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="defaultText.main"
                      sx={{ display: "inline" }}
                    >
                      &nbsp; {appleId}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      // component="span"
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      CHN ID:
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="defaultText.main"
                      sx={{ display: "inline" }}
                    >
                      &nbsp; {CHNusername}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      // component="span"
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      Zoom Link:
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="defaultText.main"
                      sx={{ display: "inline" }}
                    >
                      &nbsp; {zoomURL}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Button
                      component={Link}
                      to={`imessage://${appleId}`}
                      target="_blank"
                      // variant="outlined"
                      color="coloredText"
                      size="small"
                      sx={{
                        padding: "10px 0",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <MailOutline sx={{ marginRight: "10px" }} /> Message
                      &nbsp; {appleId}
                    </Button>
                    <Button
                      component={Link}
                      to={zoomURL}
                      target="_blank"
                      // variant="outlined"
                      color="coloredText"
                      size="small"
                      sx={{
                        padding: "10px 0",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      <VideoChat sx={{ marginRight: "10px" }} /> START ZOOM
                      &nbsp;
                    </Button>
                  </Box>
                </Collapse>
                <Box sx={{ justifyContent: "flex-end" }}></Box>
                <Button
                  size="small"
                  sx={{
                    // marginLeft: "auto",
                    float: "right",
                    padding: "5px 0 5px 0",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    color: "coloredText.main",
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  SHOW DETAILS {expanded ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Box>
            </Box>
          )}
          {page === "navigator" && (
            <Box>
              <Typography sx={{ marginBottom: 3 }} variant="h5">
                {username}{" "}
              </Typography>
              <Box>
                <Collapse
                  in={expanded}
                  variant="outlined"
                  timeout="auto"
                  unmountOnExit
                >
                  <Box>
                    <Typography
                      // component="span"
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      Apple ID:
                    </Typography>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="defaultText.main"
                      sx={{ display: "inline" }}
                    >
                      &nbsp; {NavAppleId}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      // component="span"
                      variant="subtitle2"
                      color="coloredText.main"
                      sx={{ display: "inline" }}
                    >
                      Password:
                    </Typography>
                    &nbsp;
                    {!passwordVisible ? (
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="defaultText.main"
                        sx={{ display: "inline" }}
                      >
                        •••••••••• &nbsp;{" "}
                        {/* <Visibility onClick={() => setPasswordVisible(true)} />{" "} */}
                      </Typography>
                    ) : (
                      <Typography
                        component="span"
                        variant="subtitle1"
                        color="defaultText.main"
                        sx={{ display: "inline" }}
                      >
                        {"password"} &nbsp;{" "}
                        <VisibilityOff
                          onClick={() => setPasswordVisible(false)}
                        />
                      </Typography>
                    )}
                  </Box>
                  <Button
                    component={Link}
                    to={`imessage://${NavAppleId}`}
                    target="_blank"
                    // variant="outlined"
                    color="coloredText"
                    size="small"
                    sx={{ padding: "10px 0", marginBottom: "10px" }}
                  >
                    <MailOutline sx={{ marginRight: "10px" }} /> Message &nbsp;{" "}
                    {appleId}
                  </Button>
                </Collapse>
                <Box sx={{ justifyContent: "flex-end" }}></Box>
                <Button
                  size="small"
                  // variant="outlined"
                  sx={{
                    // marginLeft: "auto",
                    float: "right",
                    padding: "25px 0 10px 0",
                    color: "coloredText.main",
                  }}
                  onClick={() => setExpanded(!expanded)}
                >
                  SHOW DETAILS {expanded ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Box>
            </Box>
          )}
          {page === "participant" && (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ float: "left" }} variant="h5">
                  {participantId}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: "flex-end",
                    alignItems: "flex-start",
                  }}
                >
                  <Button
                    component={Link}
                    to={`imessage://${appleId}`}
                    target="_blank"
                    // variant="outlined"
                    color="coloredText"
                    size="small"
                    sx={{
                      // padding: "10px 0",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "10px",
                      marginTop: "10px",
                      float: "right",
                    }}
                  >
                    <MailOutline sx={{ marginRight: "10px" }} /> Message &nbsp;{" "}
                    {appleId}
                  </Button>
                  <Button
                    component={Link}
                    to={zoomURL}
                    target="_blank"
                    // variant="outlined"
                    color="coloredText"
                    size="small"
                    float="right"
                    sx={{
                      // padding: "10px 0",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      marginBottom: "0px",
                    }}
                  >
                    <VideoChat sx={{ marginRight: "10px" }} /> START ZOOM &nbsp;
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ paddingTop: "2.5rem" }}
                  color="coloredText.main"
                >
                  &nbsp; Age:
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ paddingTop: "2.5rem" }}
                  color="defaultText.main"
                >
                  &nbsp; {age} &nbsp; | &nbsp;
                </Typography>
                <Typography
                  component="span"
                  variant="subtitle2"
                  sx={{ paddingTop: "2.5rem" }}
                  color="coloredText.main"
                >
                  Zoom URL:
                </Typography>

                <Typography
                  variant="subtitle1"
                  sx={{ paddingTop: "2.5rem" }}
                  color="defaultText.main"
                >
                  &nbsp; {zoomURL} &nbsp; | &nbsp;
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
