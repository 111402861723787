import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
export default function LoadingScreen() {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "100vh",
            }}
        >
            <Typography variant="h1" color="primary">
                Loading
            </Typography>
            <br />
            <CircularProgress color="primary" size={100} />
        </Box>
    );
}
