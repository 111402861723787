import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  TextInput,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import utc from "dayjs/plugin/utc";
import axios, { all } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import APIParticipant from "../APIParticipant";
import { set } from "date-fns";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

dayjs.extend(utc);

export default function EditNavigator({
  editingID,
  data,
  name,
  id,
  close,
  fetchData,
  editOverlayData,
}) {
  const [chnId, setChnId] = useState("");

  // const [resetPassword, setResetPassword] = useState(0);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [CHNidProvidedError, setCHNidProvidedError] = useState(false);
  const [CHNidSpaceError, setCHNidSpaceError] = useState(false);
  const [CHNidSpecialCharacterError, setCHNidSpecialCharacterError] =
    useState(false);
  const [passwordProvidedError, setPasswordProvidedError] = useState(false);
  const [passwordSpaceError, setPasswordSpaceError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordNoNumberError, setPasswordNoNumberError] = useState(false);

  const [passwordMismatchError, setPasswordMismatchError] = useState(false);

  useEffect(() => {
    if (editOverlayData) {
      setChnId(editOverlayData.CHNusername);
    }
  }, [editOverlayData]);

  const { keycloak } = useKeycloak();

  function clearAll() {
    setChnId("");
    // setResetPassword(0);
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");
  }

  useEffect(() => {
    setPasswordMismatchError(
      newPassword.length &&
        confirmPassword.length &&
        confirmPassword !== newPassword
    );
  }, [newPassword, confirmPassword]);

  //Builds chn id text feild
  const chnIdText = () => {
    //CHN ID text field on Add navigator page
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          CHN ID
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          value={chnId}
          disabled
          placeholder=""
          helperText={
            CHNidProvidedError
              ? "CHN ID is required"
              : CHNidSpaceError
              ? "CHN ID cannot contain spaces"
              : CHNidSpecialCharacterError
              ? "CHN ID cannot contain special characters"
              : ""
          }
          error={
            CHNidProvidedError || CHNidSpaceError || CHNidSpecialCharacterError
          }
          onChange={(e) => {
            setCHNidSpaceError(e.target.value && e.target.value.includes(" "));
            setCHNidSpecialCharacterError(!alphanumeric.test(chnId));
            setChnId(e.target.value);
          }} //setParticipant
        />
      </>
    );
  };

  //Builds password text field
  const passwordText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Password
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          value={password}
          type="password"
          helperText={
            passwordProvidedError
              ? "Password is required"
              : passwordSpaceError
              ? "Password cannot contain spaces"
              : passwordLengthError
              ? "Password must be between 8 and 20 characters"
              : passwordNoNumberError
              ? "Password must contain a number"
              : ""
          }
          error={
            passwordProvidedError ||
            passwordSpaceError ||
            passwordLengthError ||
            passwordNoNumberError
          }
          onChange={(e) => {
            setPasswordSpaceError(
              e.target.value && e.target.value.includes(" ")
            );
            setPasswordLengthError(
              e.target.value.length > 20 || e.target.value.length < 8
            );
            setPasswordNoNumberError(!/\d/.test(e.target.value));
            setPassword(e.target.value);
          }}
        />
        {/* const [passwordSpaceError, setPasswordSpaceError] = useState(false); */}
        {/* const [passwordLengthError, setPasswordLengthError] = useState(false); */}
        {/* const [passwordNoNumberError, setPasswordNoNumberError] = useState(false); */}

        {/* <Button
            onClick={() => {
              setResetPassword(1);
            }}
          >
            RESET PASSWORD
          </Button> */}
      </>
    );
  };

  //Builds new password text feild
  const newPasswordText = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          New Password
        </Typography>
        <TextField
          rows={1}
          sx={{
            width: "100%",
          }}
          value={newPassword}
          placeholder=""
          // type="password"
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <Typography>
          Passwords must be no more than 20 characters. Letters and numbers
          only, no special characters.
        </Typography>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Confirm New Password
        </Typography>
        <TextField
          helperText={passwordMismatchError ? "Passwords do not match" : ""}
          error={passwordMismatchError}
          rows={1}
          sx={{
            width: "100%",
          }}
          value={confirmPassword}
          placeholder=""
          // type="password"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />
      </>
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        Edit Navigator
      </Typography>

      {chnIdText()}
      {newPasswordText()}

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            (async () => {
              if (!chnId.trim().length) setCHNidProvidedError(true);
              if (!password.trim().length) setPasswordProvidedError(true);

              console.log("adding and editing a navigator");
              try {
                await APIParticipant.put(`v1/api/navigator/resetpassword`, {
                  id: data.find((chn) => chn.username === chnId).id,
                  password: newPassword,
                });

                // await axios({
                //   method: "PUT",
                //   url: "http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/navigator/resetpassword",
                //   headers: {
                //     Authorization: `Bearer ${keycloak.token}`,
                //   },
                //   data: {
                //     id: data.find((chn) => chn.username === chnId).id,
                //     password: newPassword,
                //   },
                // });
                // console.log(id);
                close();
                await fetchData();
              } catch (error) {
                console.log(error);
              }
            })();
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          Reset Password
        </Button>

        {/* {(eventTypeProvidedError ||
          eventDateProvidedError ||
          eventStartTimeProvidedError ||
          eventEndTimeProvidedError ||
          eventEndBeforeStartError ||
          idProvidedError) && (
          <Alert severity="error" sx={{ width: "100%" }}>
            hello
          </Alert>
        )} */}
      </Box>
    </Box>
  );
}
