import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  TextInput,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import utc from "dayjs/plugin/utc";
import axios, { all } from "axios";
import { useKeycloak } from "@react-keycloak/web";
import APIParticipant from "../APIParticipant";
import { set } from "date-fns";
import APIEvent from "../APIEvent";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

dayjs.extend(utc);

export default function EditToDo({ close, fetchData, editOverlayData }) {
  const [participant, setParticipant] = useState(0);

  // const [resetPassword, setResetPassword] = useState(0);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [encounterType, setEncounterType] = useState(0);
  const [encounterDate, setEncounterDate] = useState(new Date());
  const [encounterStartTime, setEncounterStartTime] = useState(undefined);
  const [encounterEndTime, setEncounterEndTime] = React.useState(undefined);
  const [toDoDescription, setToDoDescription] = useState("");
  const [participantData, setParticipantData] = useState([]);

  const [idProvidedError, setIdProvidedError] = useState(false);

  const [eventTypeProvidedError, setEventTypeProvidedError] = useState(false);
  const [eventDateProvidedError, setEventDateProvidedError] = useState(false);

  const { keycloak } = useKeycloak();

  const appointmentEncounterTypes = [
    "Choose Encounter Type",
    "Initial contact",
    "Follow-up #1",
    "Follow-up #2",
    "Follow-up #3",
    "Follow-up #4",
    "Close-out",
    "Non-required contact",
    "Accompany to Screening",
  ];

  function clearAll() {
    setParticipant(0);

    // setResetPassword(0);

    setNewPassword("");
    setConfirmPassword("");

    setEncounterType(0);
    setEncounterDate(new Date());
    setEncounterStartTime(new Date());
    setEncounterEndTime(new Date());
    setToDoDescription("");
  }

  useEffect(() => {
    const fetchPatientList = async () => {
      try {
        const { data } = await APIParticipant.get("/v1/api/patient");

        setParticipantData(data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (keycloak.tokenParsed.roles.includes("Navigator")) fetchPatientList();
  }, [keycloak]);

  useEffect(() => {
    if (editOverlayData) {
      console.log("editOverlayData for edit todo: ", editOverlayData);
      setParticipant(editOverlayData.patient.id);
      setEncounterType(editOverlayData.title);
      setEncounterDate(dayjs(editOverlayData.start));
      setToDoDescription(editOverlayData.description);
    }
  }, [editOverlayData]);

  //Builds participants dropdown selection in event add
  const participantDropdown = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Participant ID
        </Typography>
        <FormControl
          error={idProvidedError}
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: "background.main",
          }}
        >
          <Select
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "background.main",
            }}
            defaultValue={editOverlayData.patient.id}
            value={participant}
            label="Select Participant"
            helperText={idProvidedError ? "participant is required" : ""}
            onChange={(e) => {
              setParticipant(e.target.value);
            }} //setParticipant
          >
            {participantData.map((participant) => (
              <MenuItem value={participant.id} key={participant.id}>
                {participant.participantId}
              </MenuItem>
            ))}
            {/* </>}  */}
          </Select>
          <FormHelperText>
            {idProvidedError ? "Participant is Required" : ""}
          </FormHelperText>
        </FormControl>
      </>
    );
  };

  //Builds encounters dropdown selection
  const encounterDropdown = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Encounter Type
        </Typography>
        <Select
          sx={{
            width: "100%",
            height: 50,
            backgroundColor: "background.main",
          }}
          defaultValue={editOverlayData.title}
          value={encounterType}
          onChange={(e) => {
            setEncounterType(e.target.value);
          }}
        >
          {appointmentEncounterTypes.map((type) => (
            <MenuItem value={type}>{type}</MenuItem>
          ))}
        </Select>
      </>
    );
  };

  //Builds date picker
  const datePicker = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Due Date
        </Typography>{" "}
        {/* Update Date here*/}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <DatePicker
              // label="Basic date picker"
              selected={encounterDate}
              onChange={(newValue) => {
                setEncounterDate(newValue);
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </>
    );
  };

  //Builds description text area
  const description = () => {
    return (
      <>
        <Typography sx={{ marginTop: 3 }} variant="body1">
          Description
        </Typography>{" "}
        {/* Update Date here*/}
        <TextField
          rows={3}
          sx={{
            width: "100%",
          }}
          value={toDoDescription}
          placeholder={toDoDescription}
          multiline
          onChange={(newValue) => setToDoDescription(newValue.target.value)}
        />
      </>
    );
  };

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        Edit To Do
      </Typography>
      {participantDropdown()}
      {encounterDropdown()}
      {datePicker()}

      {description()}

      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            (async () => {
              if (!participant) setIdProvidedError(true);
              if (!encounterType) setEventTypeProvidedError(true);
              if (!encounterDate) setEventDateProvidedError(true);

              console.log("adding and editing a todo");

              try {
                await APIEvent.put(`/v1/event/${editOverlayData.id}`, {
                  title: encounterType,
                  description: toDoDescription,
                  editable: true,
                  category: "Todo",
                  patient: {
                    id: participant,
                  },
                  allDay: true,
                  start:
                    dayjs(encounterDate).format("YYYY-MM-DD") +
                    "T" +
                    dayjs(encounterStartTime).format("hh:mm") +
                    ":00.000+00:00",
                  end:
                    dayjs(encounterDate).format("YYYY-MM-DD") +
                    "T" +
                    dayjs(encounterEndTime).format("hh:mm") +
                    ":00.000+00:00",
                  id: editOverlayData.id,
                });
                // await axios({
                //   method: "PUT",
                //   url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-event-api/v1/event/${editOverlayData.id}`,

                //   headers: {
                //     Authorization: `Bearer ${keycloak.token}`,
                //   },
                //   data: {
                //     title: encounterType,
                //     description: toDoDescription,
                //     editable: true,
                //     category: "Todo",
                //     patient: {
                //       id: participant,
                //     },
                //     allDay: true,
                //     start:
                //       dayjs(encounterDate).format("YYYY-MM-DD") +
                //       "T" +
                //       dayjs(encounterStartTime).format("hh:mm") +
                //       ":00.000+00:00",
                //     end:
                //       dayjs(encounterDate).format("YYYY-MM-DD") +
                //       "T" +
                //       dayjs(encounterEndTime).format("hh:mm") +
                //       ":00.000+00:00",
                //     id: editOverlayData.id,
                //   },
                // });
                close();
                await fetchData();
              } catch (error) {
                alert(error?.response?.data);
              }
            })();
            // close();
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          Edit To Do HELLO
        </Button>

        {/* {(eventTypeProvidedError ||
          eventDateProvidedError ||
          eventStartTimeProvidedError ||
          eventEndTimeProvidedError ||
          eventEndBeforeStartError ||
          idProvidedError) && (
          <Alert severity="error" sx={{ width: "100%" }}>
            hello
          </Alert>
        )} */}
      </Box>
    </Box>
  );
}
