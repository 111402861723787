import React, { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

import { useNavigate } from "react-router-dom";
import { currentPage } from "../currentPage";
import { useTheme, Grid, Divider } from "@mui/material";
// components
import { LoadingScreen, VideoCard } from "../../components";
import { Typography, Box } from "@mui/material";
import { Error } from "../../components";
import APICourse from "../../APICourse";

export default function LearnPage() {
  const theme = useTheme();
  const { keycloak } = useKeycloak();
  const navigation = useNavigate();
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [rolePlayRows, setRolePlayRows] = useState({});
  useEffect(() => {
    console.log("on load useEffect");
    (async () => {
      try {
        const data = await APICourse.get("/v1/course/user/");
        console.log(data.data);
        setData(data.data);

        let result = {};
        data.data
          .filter((course) => course.courseCategory === "PN")
          .forEach((pn) => {
            result[pn.id] = data.data.filter(
              (course) =>
                course.courseCategory === "RP" &&
                course.parentCourseId === pn.id
            );
            for (let i = -1; result[pn.id].length < 2; i--) {
              result[pn.id].push({ id: i });
            }
          });
        setRolePlayRows(result);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setError({
          code: error.response.status,
          reason: error.response.data.message,
        });
      }
    })();
  }, [keycloak.token]);

  currentPage("Learn");

  if (loading) return <LoadingScreen />;

  if (error) return <Error code={error.code} reason={error.reason} />;
  return (
    <Box
      sx={{
        position: "absolute",
        top: "153px",
        left: 0,
        width: "100%",
        backgroundColor: "background.main",
      }}
    >
      <Box
        sx={{
          padding: "2.5rem 5rem",
        }}
      >
        <Typography
          sx={{
            lineHeight: 1.3,
            padding: "0.8rem 0",
          }}
          variant="h2"
        >
          Learn
        </Typography>
      </Box>
      <Box
        sx={{
          padding: "0rem 5rem 2.5rem 5rem",
        }}
      >
        <Typography fontSize="24px" variant="subtitle1">
          USPSTF Cancer Screening Guidelines
        </Typography>

        <Grid container spacing={3} sx={{ width: "95%" }}>
          {data
            .filter((course) => course.courseCategory === "CG")
            .map((course) => (
              <Grid key={course.id} item xs={4}>
                <VideoCard course={course} />
              </Grid>
            ))}
        </Grid>

        <Box
          sx={{
            paddingTop: "2.5rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "61%",
          }}
        >
          <Typography fontSize="24px" variant="subtitle1">
            Participant Navigation Tools
          </Typography>
          {/* <Typography variant="subtitle1">|</Typography> */}
          <Typography fontSize="24px" variant="subtitle1">
            Associated Role-Play Videos
          </Typography>
        </Box>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={6}
          sx={{ width: "95%" }}
          // justifyContent="center"
          alignItems="center"
          columns={13}
        >
          {data
            .filter((course) => course.courseCategory === "PN")
            .map((course) => (
              <>
                <Grid key={course.id} item xs={4}>
                  <VideoCard course={course} />
                </Grid>
                <Divider
                  orientation="vertical"
                  variant="inset"
                  flexItem
                  sx={{
                    ml: "auto",
                    mr: "auto",
                    bgcolor: theme.palette.primary.main,
                  }}
                />
                {rolePlayRows[course.id].map((rp) => (
                  <Grid key={rp.id} item xs={4}>
                    {rp.id > 0 && <VideoCard course={rp} />}
                  </Grid>
                ))}
              </>
            ))}
        </Grid>
      </Box>
    </Box>
  );
}
