// There is a full link in the Video player files for the setVideoSrc()
import axios from "axios";
import keycloak from "./keycloak"; // Adjust the import path

const API_PARTICIPANT_URL = "https://chw-patient-info-api.gallaudet.in";
// Replace with your API base URL

// const API_PARTICIPANT_URL = not ready

const APIParticipant = axios.create({
  baseURL: API_PARTICIPANT_URL,
});

// Intercept requests and add Keycloak token header
APIParticipant.interceptors.request.use(async (config) => {
  const token = await keycloak.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default APIParticipant;
