import { React, useState, useRef, useEffect } from "react";
import APIContext from "../../APIContext";
import "./VideoPlayer.css";
import { formatTime } from "./format.js";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import {
  Typography,
  Grid,
  Menu,
  MenuItem,
  Slider,
  Container,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  PlayArrowRounded,
  PauseRounded,
  SkipPreviousRounded,
  SkipNextRounded,
  SettingsRounded,
  FullscreenExit,
  Fullscreen,
  FormatSize,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { currentPage } from "../currentPage";
import { Error } from "../../components";
import APIVideo from "../../APIVideo.js";

export default function VideoPlayer() {
  currentPage("Learn");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { keycloak } = useKeycloak();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const videoPlayer = useRef(null);
  const [videoswitch, setIsVideo] = useState(true);
  const [currentValue, setCurrentValue] = useState(0);
  const [transToggle, setTrans] = useState(true);
  // const [fullscreenToggle, setFullscreen] = useState(true);
  const [transcriptParagraphs, setTranscriptParagraphs] = useState([]);
  const [transcriptFontSize, setTranscriptFontSize] = useState("14pt");
  // const [videoKey, setVideoKey] = useState("");
  const [courseInfo, setCourseInfo] = useState(undefined);
  const [videoSrc, setVideoSrc] = useState(undefined);
  // const [vidDuration, setVidDuration] = useState("17:31");
  // const video = document.querySelector("video");

  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  //speed menu
  const [anchorEl, setAnchorEl] = useState(null);

  //font size menu
  const [anchorFont, setAnchorFont] = useState(null);

  const showFontMenu = (event) => {
    setAnchorFont(event.currentTarget);
  };

  const closeFontMenu = () => {
    setAnchorFont(null);
  };

  useEffect(() => {
    (async () => {
      try {
        if (location.state) {
          setCourseInfo({
            ...location.state,
            courseDuration: location.state.courseDuration.slice(3),
          });
        } else {
          console.log("no state, fetching course info");
          // let allCoursesResponse = await axios.get(
          //     `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-course-api/v1/course/user`,
          //     {
          //         headers: {
          //             Authorization: `Bearer ${keycloak.token}`,
          //         },
          //     }
          // );
          const allCoursesResponse = await APIContext.get("/v1/course/user");

          // console.log(allCoursesResponse.data);
          let course = allCoursesResponse.data.find(
            (item) => item.id === parseInt(id)
          );
          setCourseInfo({
            ...course,
            courseDuration: course.courseDuration.slice(3),
          });
        }
        // let transcriptResponse = await axios.get(
        //     `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-video-streaming-api/v1/media/file/srt/course/${id}`,
        //     {
        //         headers: {
        //             Authorization: `Bearer ${keycloak.token}`,
        //             Range: "bytes=0-800",
        //         },
        //     }
        // );

        const transcriptResponse = await APIVideo.get(
          `/v1/media/file/srt/course/${id}`,
          { headers: { Range: "bytes=0-800" } }
        );

        // console.log("trans: " + transcriptResponse.data);
        let correctedTranscriptParagraphs = transcriptResponse.data
          .split("")
          .map((char) => (char === "�" ? "'" : char))
          .join("")
          .split("\r\n")
          .filter((line) => line.length);

        setTranscriptParagraphs(correctedTranscriptParagraphs);

        let KeyResponse = await axios.get(
          `https://chw-video-streaming-api.gallaudet.in/v1/media/video/stream/course/${id}/presignedurl`,
          {
            headers: {
              Authorization: `Bearer ${keycloak.token}`,
              Range: "bytes=0-800",
            },
          }
        );
        // const KeyResponse = await APIVideo.get(
        //   `/v1/media/video/stream/generate-key`,
        //   { headers: { Range: "bytes=0-800" } }
        // );

        setVideoSrc(KeyResponse.data);
        setIsLoaded(true);
      } catch (error) {
        console.error(error);
        setError(error);
      }
    })();
  }, [id, keycloak.token, location.state]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event, newValue) => {
    setCurrentValue(newValue);
    videoPlayer.current.currentTime =
      (newValue / 100) * videoPlayer.current.duration;
  };

  const handleProgress = () => {
    document.getElementById("currentTime").innerHTML = formatTime(
      videoPlayer.current.currentTime
    );
    setCurrentValue(
      (videoPlayer.current.currentTime / videoPlayer.current.duration) * 100
    );
  };

  const playPauseHandler = () => {
    //plays and pause the video (toggling) changes
    if (videoswitch) {
      setIsVideo(false);
      videoPlayer.current.play();
      console.log(document.getElementById("seek-bar").value);
      document.getElementById("play").style.display = "none";
      document.getElementById("pause").style.display = "block";
    } else {
      setIsVideo(true);
      videoPlayer.current.pause();
      document.getElementById("play").style.display = "block";
      document.getElementById("pause").style.display = "none";
    }
  };

  document.addEventListener("fullscreenchange", (event) => {
    if (document.fullscreenElement) {
      // We’re going fullscreen
      console.log("FULLSCREEN");
      document.getElementById("full-scr").style.display = "none";
      document.getElementById("full-scr-dis").style.display = "block";
    } else {
      // We’re exiting fullscreen
      console.log("EXITING FULLSCREEN");
      document.getElementById("full-scr").style.display = "block";
      document.getElementById("full-scr-dis").style.display = "none";
    }
  });

  const fullscreenHandler = () => {
    //plays and pause the video (toggling)
    if (document.fullscreenElement === null) {
      let elem = document.getElementById("vid");
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  const rewindHandler = () => {
    //Rewinds the video player reducing 5
    if (isLoaded) {
      console.log(videoPlayer.current.currentTime);
      // console.log(videoPlayer.current.getCurrentTime());
      videoPlayer.current.currentTime = videoPlayer.current.currentTime - 10;
      // videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
    }
  };

  const handleFastForward = () => {
    //FastFowards the video player by adding 10
    videoPlayer.current.currentTime = videoPlayer.current.currentTime + 10;
  };

  function transHandle() {
    if (transToggle) {
      setTrans(false);
      // console.log("CLICKED");
      document.getElementById("grid-vid").style.width = "90%";
      document.getElementById("grid-trans").style.width = "10%";
      document.getElementById("trans-display-btn-vis").style.display = "block";
      document.getElementById("trans-display-btn-non-vis").style.display =
        "none";
      document.getElementsByClassName("trans-con")[1].style.display = "none";
      document.getElementsByClassName("trans-con")[2].style.display = "none";
      document.getElementsByClassName("trans-con-child")[0].style.display =
        "none";
    } else {
      setTrans(true);
      // console.log("CLICKED here");
      document.getElementById("grid-vid").style.width = "70%";
      document.getElementById("grid-trans").style.width = "30%";
      document.getElementById("trans-display-btn-vis").style.display = "none";
      document.getElementById("trans-display-btn-non-vis").style.display =
        "block";
      document.getElementsByClassName("trans-con")[1].style.display = "flex";
      document.getElementsByClassName("trans-con")[2].style.display = "flex";
      document.getElementsByClassName("trans-con-child")[0].style.display =
        "flex";
    }
  }

  if (error) {
    return <Error code={error.status} reason={error.message} />;
  } else if (!isLoaded) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          height: "100vh",
        }}
      >
        <Typography variant="h1" color="primary">
          Loading
        </Typography>
        <br />
        <CircularProgress color="primary" size={100} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          backgroundColor: "background.main",
        }}
      >
        <Container fluid>
          {/* <div className="container"> */}
          <div className="top-text-container">
            <button className="btn_back btn" onClick={() => navigate("/learn")}>
              <Typography variant="backButton">
                {"<< BACK TO ALL VIDEOS"}
              </Typography>
            </button>
            <Typography variant="h4">{courseInfo.courseName}</Typography>
            <Typography variant="subtitle1">
              {courseInfo.courseDuration}
            </Typography>
            <Typography variant="body1">{courseInfo.description}</Typography>
          </div>
          <div id="mid-video-container">
            <Grid
              container
              id="vid-tran-grid"
              spacing={0}
              direction="row"
              columns={16}
              sx={{ border: 1, borderRadius: "10px" }}
            >
              <Grid id="grid-vid" item>
                <div id="vid-con">
                  <video
                    ref={videoPlayer}
                    onTimeUpdate={handleProgress}
                    preload="metadata"
                    id="vid"
                    playbackRate={videoState.playbackRate}
                    // ontimeupdate={controlScroll}
                  >
                    <source type="video/mp4" src={videoSrc} />
                  </video>
                </div>
              </Grid>
              <Grid
                id="grid-trans"
                item
                sx={{ backgroundColor: "background.paper" }}
              >
                <div id="trans-con">
                  <div className="trans-con">
                    <Typography
                      className="trans-con-child"
                      variant="overline2"
                      sx={{ color: "defaultText.main" }}
                    >
                      TRANSCRIPTION
                    </Typography>
                    <button
                      style={{ marginLeft: "auto" }}
                      className="iconBtn trans-con-child-right btn"
                      onClick={transHandle}
                    >
                      <Visibility
                        id="trans-display-btn-vis"
                        sx={{
                          color: "icons.main",
                          display: "none",
                        }}
                        size="30"
                      />
                      <VisibilityOff
                        id="trans-display-btn-non-vis"
                        sx={{ color: "icons.main" }}
                        size="30"
                      />
                    </button>
                  </div>
                  <div className="trans-con">
                    <button
                      className="iconBtn btn"
                      style={{ marginLeft: "auto" }}
                      onClick={showFontMenu}
                    >
                      <FormatSize sx={{ color: "icons.main" }} size="30" />
                    </button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorFont}
                      keepMounted
                      open={Boolean(anchorFont)}
                      onClose={closeFontMenu}
                    >
                      <MenuItem
                        sx={{
                          display: "block",
                          float: "none",
                        }}
                        onClick={() => {
                          closeFontMenu();
                          setTranscriptFontSize("14pt");
                        }}
                      >
                        Normal
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "block",
                          float: "none",
                        }}
                        onClick={() => {
                          closeFontMenu();
                          setTranscriptFontSize("18pt");
                        }}
                      >
                        Large
                      </MenuItem>
                      <MenuItem
                        sx={{
                          display: "block",
                          float: "none",
                        }}
                        onClick={() => {
                          closeFontMenu();
                          setTranscriptFontSize("24pt");
                        }}
                      >
                        Largest
                      </MenuItem>
                    </Menu>
                  </div>
                  <div className="trans-con">
                    <div className="tran-text">
                      {transcriptParagraphs.map((paragraph) => (
                        <>
                          <Typography
                            variant="transcript"
                            sx={{
                              fontSize: transcriptFontSize,
                              color: "defaultText.main",
                            }}
                          >
                            {paragraph}
                          </Typography>
                          <br />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="video-controls-container">
              <div className="video-controls">
                <button className="controlBtn controlText" type="button">
                  <Typography id="currentTime" variant="video">
                    00:00
                  </Typography>
                </button>
                <div className="seekbar">
                  <Slider
                    style={{ color: "white" }}
                    id="seek-bar"
                    aria-label="video-seek"
                    defaultValue={0}
                    value={currentValue}
                    onChange={handleChange}
                  />
                </div>
                <button className="controlBtn controlText" type="button">
                  <Typography id="vidDuration" variant="video">
                    {courseInfo.courseDuration}
                  </Typography>
                </button>
                <button
                  className="controlBtn btn"
                  type="button"
                  onClick={rewindHandler}
                >
                  <SkipPreviousRounded style={{ fontSize: 30 }} size="30" />
                </button>
                <button
                  className="controlBtn btn"
                  type="button"
                  title="Play/Pause"
                  id="playPause"
                  onClick={playPauseHandler}
                >
                  <PlayArrowRounded
                    className="icons"
                    id="play"
                    style={{
                      display: "block",
                      fontSize: 30,
                    }}
                    size="30"
                  />
                  <PauseRounded
                    id="pause"
                    style={{
                      display: "none",
                      fontSize: 30,
                    }}
                    size="30"
                  />
                </button>
                <button
                  className="controlBtn btn"
                  type="button"
                  onClick={handleFastForward}
                >
                  <SkipNextRounded style={{ fontSize: 30 }} size="30" />
                </button>
                <button
                  id="basic-button"
                  aria-controls={anchorEl ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? "true" : undefined}
                  onClick={handleClick}
                  className="controlBtn btn"
                >
                  <SettingsRounded
                    style={{ color: "white", fontSize: 30 }}
                    size="30"
                  />
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p>Speed:</p>
                  </Box>
                  <hr />
                  <MenuItem
                    sx={{
                      display: "block",
                      float: "none",
                      textAlign: "center",
                    }}
                    className="speedMenu-items"
                    onClick={() => {
                      handleClose();
                      videoPlayer.current.playbackRate = 0.5;
                    }}
                  >
                    0.5
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "block",
                      float: "none",
                      textAlign: "center",
                    }}
                    className="speedMenu-items"
                    onClick={() => {
                      handleClose();
                      videoPlayer.current.playbackRate = 1.0;
                    }}
                  >
                    Normal
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "block",
                      float: "none",
                      textAlign: "center",
                    }}
                    className="speedMenu-items"
                    onClick={() => {
                      handleClose();
                      videoPlayer.current.playbackRate = 1.5;
                    }}
                  >
                    1.5
                  </MenuItem>
                  <MenuItem
                    sx={{
                      display: "block",
                      float: "none",
                      textAlign: "center",
                    }}
                    className="speedMenu-items"
                    onClick={() => {
                      handleClose();
                      videoPlayer.current.playbackRate = 2.0;
                    }}
                  >
                    2
                  </MenuItem>
                </Menu>
                <button
                  className="controlBtn btn"
                  type="button"
                  title="Fullscreen"
                  onClick={fullscreenHandler}
                >
                  <Fullscreen
                    id="full-scr"
                    style={{
                      display: "block",
                      fontSize: 30,
                    }}
                    size="30"
                  />
                  <FullscreenExit
                    id="full-scr-dis"
                    style={{
                      display: "none",
                      fontSize: 30,
                    }}
                    size="30"
                  />
                </button>
              </div>
            </div>
          </div>
          <br />
          <br />
        </Container>
      </Box>
    );
  }
}
