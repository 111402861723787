import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  TextField,
  TextInput,
  Alert,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import utc from "dayjs/plugin/utc";
import axios, { all } from "axios";
import { useKeycloak } from "@react-keycloak/web";

import APIParticipant from "../APIParticipant";
import { set } from "date-fns";

const alphanumeric = new RegExp(/^[a-zA-Z0-9._]*$/);

dayjs.extend(utc);

export default function AddOverlay({
  type,
  event,
  editingID,
  data,
  name,
  id,
  close,
  fetchData,
  resetPassword = 0,
  editingData,
}) {
  const [participant, setParticipant] = useState(0);
  const [participantAge, setParticipantAge] = useState(
    editingData ? editingData.age : 0
  );
  const [newParticipant, setNewParticipant] = useState("");
  const [appleId, setAppleId] = useState(
    editingData ? editingData.appleId : ""
  );
  const [chnId, setChnId] = useState(editingData ? editingData.chnId : "");
  const [zoomLink, setZoomLink] = useState(
    editingData ? editingData.zoomLink : ""
  );

  // const [resetPassword, setResetPassword] = useState(0);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [encounterType, setEncounterType] = useState(0);
  const [encounterDate, setEncounterDate] = useState(new Date());
  const [encounterStartTime, setEncounterStartTime] = useState(undefined);
  const [encounterEndTime, setEncounterEndTime] = React.useState(undefined);
  const [toDoDescription, setToDoDescription] = useState(
    editingData ? editingData.description : ""
  );
  const [participantData, setParticipantData] = useState([]);
  const [ageValueError, setAgeValueError] = useState(false);
  const [ageProvidedError, setAgeProvidedError] = useState(false);
  const [idProvidedError, setIdProvidedError] = useState(false);
  const [idSpaceError, setIdSpaceError] = useState(false);
  const [idSpecialCharacterError, setIdSpecialCharacterError] = useState(false);
  const [CHNidProvidedError, setCHNidProvidedError] = useState(false);
  const [appleIdProvidedError, setAppleIdProvidedError] = useState(false);
  const [appleIdSpaceError, setAppleIdSpaceError] = useState(false);
  const [appleIdAtError, setAppleIdAtError] = useState(false);
  const [zoomLinkProvidedError, setZoomLinkProvidedError] = useState(false);
  const [zoomLinkSpaceError, setZoomLinkSpaceError] = useState(false);

  const [CHNidSpaceError, setCHNidSpaceError] = useState(false);
  const [CHNidSpecialCharacterError, setCHNidSpecialCharacterError] =
    useState(false);
  const [passwordProvidedError, setPasswordProvidedError] = useState(false);
  const [passwordSpaceError, setPasswordSpaceError] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [passwordNoNumberError, setPasswordNoNumberError] = useState(false);

  const [passwordMismatchError, setPasswordMismatchError] = useState(false);

  const [eventTypeProvidedError, setEventTypeProvidedError] = useState(false);
  const [eventDateProvidedError, setEventDateProvidedError] = useState(false);
  const [eventStartTimeProvidedError, setEventStartTimeProvidedError] =
    useState(false);
  const [eventEndTimeProvidedError, setEventEndTimeProvidedError] =
    useState(false);
  const [eventEndBeforeStartError, setEventEndBeforeStartError] =
    useState(false);

  const { keycloak } = useKeycloak();

  const [viewingParticipant, setViewingParticipant] = useState(1);

  const appointmentEncounterTypes = [
    "Choose Encounter Type",
    "Initial contact",
    "Follow-up #1",
    "Follow-up #2",
    "Follow-up #3",
    "Follow-up #4",
    "Close-out",
    "Non-required contact",
    "Accompany to Screening",
  ];

  function clearAll() {
    setParticipant(0);
    setParticipantAge();
    setNewParticipant("");
    setAppleId("");
    setChnId("");
    setZoomLink("");

    // setResetPassword(0);
    setPassword("");
    setNewPassword("");
    setConfirmPassword("");

    setEncounterType(0);
    setEncounterDate(new Date());
    setEncounterStartTime(new Date());
    setEncounterEndTime(new Date());
    setToDoDescription("");
  }

  useEffect(() => {
    const fetchPatientList = async () => {
      try {
        const { data } = await APIParticipant.get(
          "/v1/api/patient"
        );

        setParticipantData(data);
      } catch (error) {
        console.log("error: ", error);
      }
    };
    if (keycloak.tokenParsed.roles.includes("Navigator")) fetchPatientList();
    // setResetPassword(reset);
  }, [keycloak]);

  useEffect(() => {
    setPasswordMismatchError(
      newPassword.length &&
        confirmPassword.length &&
        confirmPassword !== newPassword
    );
  }, [newPassword, confirmPassword]);

  //Builds participants dropdown selection in event add
  const participantDropdown = () => {
    if (event.participant === "dropdown") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Participant ID
          </Typography>
          <FormControl
            error={idProvidedError}
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "background.main",
            }}
          >
            <Select
              sx={{
                width: "100%",
                height: 50,
                backgroundColor: "background.main",
              }}
              value={participant}
              label="Select Participant"
              helperText={idProvidedError ? "participant is required" : ""}
              onChange={(e) => {
                setParticipant(e.target.value);
              }} //setParticipant
            >
              {participantData.map((participant) => (
                <MenuItem value={participant.id} key={participant.id}>
                  {participant.participantId}
                </MenuItem>
              ))}
              {/* </>}  */}
            </Select>
            <FormHelperText>
              {idProvidedError ? "Participant is Required" : ""}
            </FormHelperText>
          </FormControl>
        </>
      );
    }
  };

  //Builds encounters dropdown selection
  const encounterDropdown = () => {
    if (event.category === "dropdown") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Encounter Type
          </Typography>
          <Select
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "background.main",
            }}
            defaultValue={0}
            value={encounterType}
            onChange={(e) => {
              setEncounterType(e.target.value);
            }}
          >
            {appointmentEncounterTypes.map((type, index) => (
              <MenuItem value={index}>{type}</MenuItem>
            ))}
          </Select>
        </>
      );
    }
  };

  //Builds date picker
  const datePicker = () => {
    if (event.date === "Encounter" || event.date === "Due") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            {event.date} Date
          </Typography>{" "}
          {/* Update Date here*/}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                // label="Basic date picker"
                selected={encounterDate}
                onChange={(newValue) => {
                  setEncounterDate(newValue);
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </>
      );
    }
  };

  //Builds start and end date dropdowns
  const start_end_dates = () => {
    if (event.start_end === "dropdown") {
      return (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Box sx={{ flex: 1, marginRight: "2.5rem" }}>
              <Typography sx={{ marginTop: 3 }} variant="body1">
                Encounter Start Time
              </Typography>{" "}
              {/* Update Date here*/}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DemoContainer components={["TimePicker", "TimePicker"]}>
                  <TimePicker
                    // label="Controlled picker"
                    value={encounterStartTime}
                    maxTime={encounterEndTime}
                    onChange={(newValue) => {
                      setEncounterStartTime(newValue);
                    }}
                    textField={(props) => (
                      <TextField {...props} placeholder="" />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ marginTop: 3 }} variant="body1">
                Encounter End Time
              </Typography>{" "}
              {/* Update Date here*/}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DemoContainer components={["TimePicker", "TimePicker"]}>
                  {/* <TimePicker
                  label="Uncontrolled picker"
                  defaultValue={dayjs('2022-04-17T15:30')}
                /> */}
                  <TimePicker
                    // label="Controlled picker"
                    value={encounterEndTime}
                    minTime={encounterStartTime}
                    onChange={(newValue) => {
                      setEncounterEndTime(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>
          </Box>
        </>
      );
    }
  };

  //Builds description text area
  const description = () => {
    if (event.description === "text") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Description
          </Typography>{" "}
          {/* Update Date here*/}
          <TextField
            rows={3}
            sx={{
              width: "100%",
            }}
            value={toDoDescription}
            placeholder=""
            multiline
            onChange={(newValue) => setToDoDescription(newValue.target.value)}
          />
        </>
      );
    }
  };

  //Builds participants text field
  const participantText = () => {
    if (event.participant === "text") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Participant ID
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            placeholder=""
            onChange={(e) => {
              setIdSpaceError(e.target.value && e.target.value.includes(" "));

              setNewParticipant(e.target.value);
            }}
            helperText={
              idProvidedError
                ? "Participant ID is required"
                : idSpaceError
                ? "Participant ID cannot contain spaces"
                : idSpecialCharacterError
                ? "Participant ID cannot contain special characters"
                : ""
            }
            error={idProvidedError || idSpaceError || idSpecialCharacterError}
          />
        </>
      );
    }
  };

  //Builds participants text feild
  const participantAgeText = () => {
    if (event.participant === "text") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Age
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            placeholder=""
            inputProps={{ min: 0, max: 120 }}
            onChange={(e) => {
              setAgeValueError(
                e.target.value &&
                  (parseInt(e.target.value) < 1 ||
                    isNaN(parseInt(e.target.value)))
              );
              setParticipantAge(e.target.value);
            }}
            helperText={
              ageValueError
                ? "Age must be a positive number"
                : ageProvidedError
                ? "Age is required"
                : ""
            }
            error={ageValueError || ageProvidedError}
          />
        </>
      );
    }
  };

  //Builds apple id text feild
  const appleIdText = () => {
    if (event.appleId === "email") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Apple ID
          </Typography>
          <TextField
            rows={1}
            type="email"
            sx={{
              width: "100%",
            }}
            placeholder=""
            onChange={(e) => {
              setAppleIdSpaceError(
                e.target.value && e.target.value.includes(" ")
              );
              setAppleId(e.target.value);
            }}
            helperText={
              appleIdProvidedError
                ? "Apple ID is required"
                : appleIdSpaceError
                ? "Apple ID cannot contain spaces"
                : appleIdAtError
                ? "Apple ID must include @"
                : ""
            }
            error={appleIdProvidedError || appleIdSpaceError || appleIdAtError}
          />
        </>
      );
    }
  };

  //Builds chn id text feild
  const chnIdText = () => {
    if (event.chnId === "dropdown") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            CHN ID
          </Typography>
          <Select
            sx={{
              width: "100%",
              height: 50,
              backgroundColor: "background.main",
            }}
            value={chnId}
            label="Select Participant"
            helperText={CHNidProvidedError ? "CHN ID is required" : ""}
            error={CHNidProvidedError}
            onChange={(e) => {
              setCHNidSpaceError(
                e.target.value && e.target.value.includes(" ")
              );
              setChnId(e.target.value);
            }} //setParticipant
          >
            {/* <MenuItem value={0}>Select Participant</MenuItem> */}
            {[...new Set(data.map((event) => event.username))].map((id) => (
              <MenuItem value={id} key={id}>
                {id}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    }
    //CHN ID text field on Add navigator page
    if (event.chnId === "text") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            CHN ID
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            placeholder=""
            helperText={
              CHNidProvidedError
                ? "CHN ID is required"
                : CHNidSpaceError
                ? "CHN ID cannot contain spaces"
                : CHNidSpecialCharacterError
                ? "CHN ID cannot contain special characters"
                : ""
            }
            error={
              CHNidProvidedError ||
              CHNidSpaceError ||
              CHNidSpecialCharacterError
            }
            onChange={(e) => {
              setCHNidSpaceError(
                e.target.value && e.target.value.includes(" ")
              );
              setCHNidSpecialCharacterError(!alphanumeric.test(chnId));
              setIdSpecialCharacterError(true);
              setChnId(e.target.value);
            }} //setParticipant
          />
        </>
      );
    }
  };

  //Builds zoom link text feild
  const zoomText = () => {
    if (event.zoomLink === "text") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Zoom Link
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            placeholder=""
            onChange={(newValue) => setZoomLink(newValue.target.value)}
            helperText={
              zoomLinkProvidedError
                ? "Zoom Link is required"
                : zoomLinkSpaceError
                ? "Zoom Link cannot contain spaces"
                : ""
            }
            error={zoomLinkProvidedError || zoomLinkSpaceError}
          />
        </>
      );
    }
  };

  //Builds password text field
  const passwordText = () => {
    if (event.password === "password") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Password
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            value={password}
            type="password"
            helperText={
              passwordProvidedError
                ? "Password is required"
                : passwordSpaceError
                ? "Password cannot contain spaces"
                : passwordLengthError
                ? "Password must be between 8 and 20 characters"
                : passwordNoNumberError
                ? "Password must contain a number"
                : ""
            }
            error={
              passwordProvidedError ||
              passwordSpaceError ||
              passwordLengthError ||
              passwordNoNumberError
            }
            onChange={(e) => {
              setPasswordSpaceError(
                e.target.value && e.target.value.includes(" ")
              );
              setPasswordLengthError(
                e.target.value.length > 20 || e.target.value.length < 8
              );
              setPasswordNoNumberError(!/\d/.test(e.target.value));
              setPassword(e.target.value);
            }}
          />
          {/* const [passwordSpaceError, setPasswordSpaceError] = useState(false); */}
          {/* const [passwordLengthError, setPasswordLengthError] = useState(false); */}
          {/* const [passwordNoNumberError, setPasswordNoNumberError] = useState(false); */}

          {/* <Button
            onClick={() => {
              setResetPassword(1);
            }}
          >
            RESET PASSWORD
          </Button> */}
        </>
      );
    }
  };

  //Builds new password text feild
  const newPasswordText = () => {
    if (event.password === "password") {
      return (
        <>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            New Password
          </Typography>
          <TextField
            rows={1}
            sx={{
              width: "100%",
            }}
            value={newPassword}
            placeholder=""
            // type="password"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <Typography>
            Passwords must be no more than 20 characters. Letters and numbers
            only, no special character.
          </Typography>
          <Typography sx={{ marginTop: 3 }} variant="body1">
            Confirm New Password
          </Typography>
          <TextField
            helperText={passwordMismatchError ? "Passwords do not match" : ""}
            error={passwordMismatchError}
            rows={1}
            sx={{
              width: "100%",
            }}
            value={confirmPassword}
            placeholder=""
            // type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </>
      );
    }
  };

  return (
    <Box>
      <Typography
        variant="h6"
        color="coloredText.main"
        sx={{ textAlign: "center" }}
      >
        {resetPassword === 0
          ? `${editingID === -1 ? "Add" : "Edit"} ${name}`
          : "Reset Password"}
      </Typography>
      {participantDropdown()}
      {encounterDropdown()}
      {datePicker()}
      {start_end_dates()}
      {description()}

      {participantText()}
      {participantAgeText()}
      {chnIdText()}
      {resetPassword === 0 && appleIdText()}
      {zoomText()}
      {/* {passwordText()} */}

      {resetPassword === 0 ? passwordText() : newPasswordText()}

      {console.log("reset: " + resetPassword)}
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "2.5rem" }}
      >
        <Button
          onClick={() => {
            clearAll();
            close();
          }}
          variant="outlined"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "coloredText.main",
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            if (type === "Add or Edit Event") {
              (async () => {
                if (!participant) setIdProvidedError(true);
                if (!encounterType) setEventTypeProvidedError(true);
                if (!encounterDate) setEventDateProvidedError(true);
                if (!encounterStartTime) setEventStartTimeProvidedError(true);
                if (!encounterEndTime) setEventEndTimeProvidedError(true);
                if (encounterEndTime < encounterStartTime)
                  setEventEndBeforeStartError(true);

                console.log("adding and editing a meeting");
                try {
                  await axios({
                    method: editingID === -1 ? "POST" : "PUT",
                    url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-event-api/v1/event${
                      editingID === -1 ? "" : "/" + editingID
                    }`,

                    headers: {
                      Authorization: `Bearer ${keycloak.token}`,
                    },
                    data: {
                      title: appointmentEncounterTypes[encounterType],
                      description: " ",
                      editable: true,
                      category: "Meeting",
                      patient: {
                        id: participant,
                      },
                      allDay: false,
                      start: dayjs(
                        dayjs(encounterDate).format("YYYY-MM-DD") +
                          "T" +
                          dayjs(encounterStartTime).format("hh:mm") +
                          ":00.000"
                      )
                        .utc()
                        .toISOString(),
                      end: dayjs(
                        dayjs(encounterDate).format("YYYY-MM-DD") +
                          "T" +
                          dayjs(encounterEndTime).format("hh:mm") +
                          ":00.000"
                      )
                        .utc()
                        .toISOString(),
                      ...(editingID !== -1 && { id: editingID }),
                    },
                  });
                  close();
                  await fetchData();
                } catch (error) {
                  alert(error?.response?.data);
                }
              })();
            } else if (type === "adding and editing a todo") {
              (async () => {
                if (!participant) setIdProvidedError(true);
                if (!encounterType) setEventTypeProvidedError(true);
                if (!encounterDate) setEventDateProvidedError(true);
                if (!encounterStartTime) setEventStartTimeProvidedError(true);
                if (!encounterEndTime) setEventEndTimeProvidedError(true);

                console.log("adding and editing a todo");

                try {
                  await axios({
                    method: editingID === -1 ? "POST" : "PUT",
                    url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-event-api/v1/event${
                      editingID === -1 ? "" : "/" + editingID
                    }`,

                    headers: {
                      Authorization: `Bearer ${keycloak.token}`,
                    },
                    data: {
                      title: appointmentEncounterTypes[encounterType],
                      description: toDoDescription,
                      editable: true,
                      category: "Todo",
                      patient: {
                        id: participant,
                      },
                      allDay: true,
                      start:
                        dayjs(encounterDate).format("YYYY-MM-DD") +
                        "T" +
                        dayjs(encounterStartTime).format("hh:mm") +
                        ":00.000+00:00",
                      end:
                        dayjs(encounterDate).format("YYYY-MM-DD") +
                        "T" +
                        dayjs(encounterEndTime).format("hh:mm") +
                        ":00.000+00:00",
                      ...(editingID !== -1 && { id: editingID }),
                    },
                  });
                  close();
                  await fetchData();
                } catch (error) {
                  alert(error?.response?.data);
                }
              })();
              // close();
            } else if (type === "Add Participant") {
              (async () => {
                if (!participantAge) setAgeProvidedError(true);
                if (!newParticipant.trim().length) setIdProvidedError(true);
                if (newParticipant.includes(" ")) setIdSpaceError(true);
                if (!alphanumeric.test(newParticipant))
                  setIdSpecialCharacterError(true);
                if (!chnId.trim().length) setCHNidProvidedError(true);

                if (!zoomLink.trim().length) setZoomLinkProvidedError(true);
                if (zoomLink.includes(" ")) setZoomLinkSpaceError(true);
                if (!appleId.trim().length) setAppleIdProvidedError(true);
                if (!appleId.includes("@")) setAppleIdAtError(true);

                //checks age is not e - research why it lets me put in e

                try {
                  await axios({
                    method:
                      editingID === -1 && resetPassword !== 1 ? "POST" : "PUT",
                    url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/patient/`,
                    headers: {
                      Authorization: `Bearer ${keycloak.token}`,
                    },
                    data: {
                      age: parseInt(participantAge),
                      zoomURL: zoomLink,
                      appleId: appleId,
                      navigatorId: data.find((nav) => nav.username === chnId)
                        .id,
                      participantId: newParticipant,

                      ...(editingID !== -1 && { id: editingID }),
                    },
                  });
                  close();
                  await fetchData();
                } catch (error) {
                  alert(error?.response?.data);
                }
              })();
            } else if (type === "AddNavigator") {
              (async () => {
                if (!chnId.trim().length) setCHNidProvidedError(true);
                if (!appleId.trim().length) setAppleIdProvidedError(true);
                if (!appleId.includes("@")) setAppleIdAtError(true);
                if (!password.trim().length) setPasswordProvidedError(true);

                console.log("adding and editing a navigator");
                try {
                  await axios({
                    method:
                      editingID === -1 && resetPassword !== 1 ? "POST" : "PUT",
                    url: `http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/sh-asl-chw-patient-info/v1/api/navigator${
                      resetPassword !== 1 ? "/" : "/resetpassword"
                    }`,

                    headers: {
                      Authorization: `Bearer ${keycloak.token}`,
                    },
                    data: {
                      ...(resetPassword !== 1
                        ? {
                            firstName: "xyz12",
                            lastName: "sdsd",
                            password: password,
                            username: chnId,
                            appleId: appleId,
                          }
                        : {
                            id: data.find((chn) => chn.username === chnId).id,
                            password: newPassword,
                          }),
                    },
                  });
                  console.log(id);
                  close();
                  await fetchData();
                } catch (error) {
                  alert("error");
                }
              })();
            }
          }}
          variant="contained"
          sx={{
            margin: "10px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "coloredText.main",
            color: "white",
          }}
        >
          {resetPassword === 0
            ? `${editingID === -1 ? `Add ${name}` : "SAVE CHANGES"}`
            : "Reset Password"}
        </Button>

        {/* {(eventTypeProvidedError ||
          eventDateProvidedError ||
          eventStartTimeProvidedError ||
          eventEndTimeProvidedError ||
          eventEndBeforeStartError ||
          idProvidedError) && (
          <Alert severity="error" sx={{ width: "100%" }}>
            hello
          </Alert>
        )} */}
      </Box>
    </Box>
  );
}
