import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  Collapse,
  Box,
  CardHeader,
} from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  ExpandMore,
  ExpandLess,
  VideoChat,
  WarningAmber,
  Token,
} from "@mui/icons-material";
import AddOverlay from "./AddOverlay";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

export default function NotesCard({
  event,
  handleRemove,
  handleComplete,
  openAddApp,
  handleEdit,
}) {
  const {
    id,
    parentNoteId,
    createdDate,
    noteType,
    navigatorId,
    description,
    participant,
  } = event;

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const [expanded, setExpanded] = useState(false);

  // console.log(event);

  const handleClick = () => {
    handleEdit(event.id);
    openAddApp();
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        padding: "5px",
        marginBottom: "25px",
        borderRadius: "15px",
        backgroundColor: "background.main",
        maxWidth: "1000px",
        boxShadow: 5,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="subtitle2" color="defaultText.main" sx={{}}>
            {dayjs(event.eventDate).format("MMMM DD, YYYY").toUpperCase()}
          </Typography>
          <Button
            component={Link}
            to={`/viewnote/${id}`}
            variant="outlined"
            sx={{
              float: "right",
              marginLeft: "57%",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "coloredText.main",
              color: "coloredText.main",
            }}
          >
            VIEW NOTE
          </Button>
          <Button
            component={Link}
            to={`/appendnote/${id}`}
            variant="outlined"
            sx={{
              marginLeft: "auto",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "coloredText.main",
              color: "coloredText.main",
            }}
          >
            APPEND
          </Button>
        </Box>

        <Typography sx={{ paddingBottom: "8px" }}>
          {noteType.replace("_", " ").toUpperCase()}
        </Typography>
        <Typography variant="h5">
          {participant.participantId}
        </Typography>
        <Typography
          variant="body1"
          color="defaultText.main"
          sx={{ paddingTop: "18px" }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
