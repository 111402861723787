import React from "react";
import './assessments.css';

// function iFrameSize(){
//     var iFrame = document.getElementById('iFrame1');
//     // console.log(iFrame.contentWindow.document.body.scrollHeight);
//     console.log(iFrame.height);
//     iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
//   }

const Assessments = () => (
  <div className="iframeDiv">
    <iframe className="assessmentFrame" seamless="seamless" src="https://ucf.qualtrics.com/jfe/form/SV_8dYNaUie5LBBJNs" id="iFrame1" ></iframe>
  </div>
);

export default Assessments;

// function resizeIFrameToFitContent( iFrame ) {
//     var iFrame = document.getElementById( 'iFrame1' );
//     iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
//     iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
// }

// window.addEventListener('DOMContentLoaded', function(e) {
//     var iFrame = document.getElementById( 'iFrame1' );
//     console.log(iFrame);
//     resizeIFrameToFitContent( iFrame );
// } );