// There is a full link in the Video player files for the setVideoSrc()
import axios from "axios";
import keycloak from "./keycloak"; // Adjust the import path

const API_BASE_URL =
  "http://snap-lb-141516022.us-east-1.elb.amazonaws.com:8080/";
// Replace with your API base URL

// const API_PARTICIPANT_URL = not ready
const API_EVENT_URL = "https://chw-event-api.gallaudet.in/";
const API_COURSE_URL = "https://chw-course-api.gallaudet.in/";
const API_VIDEO_STREAMING_URL = "https://chw-video-streaming-api.gallaudet.in/";

const APIContext = axios.create({
  baseURL: API_BASE_URL,
  eventURL: API_EVENT_URL,
  courseURL: API_COURSE_URL,
  videoStreamingURL: API_VIDEO_STREAMING_URL,
});

// Intercept requests and add Keycloak token header
APIContext.interceptors.request.use(async (config) => {
  const token = await keycloak.token;
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export default APIContext;
