import React, { useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Box, AppBar, useTheme, Button, IconButton, Typography, LightMode, DarkMode, Switch, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import "./navBar.css";
import gallyLogo from "../../assets/media/GallaudetCDHE-H_white.png";
import ColorModeContext from "../../context/ColorModeContext";

let pages = [
    "Dashboard",
    "Learn",
    "Schedule",
    "Participants",
    "Notes",
    "Resources",
];

function NavBar() {
    const { keycloak } = useKeycloak();
    const navigation = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const [loginDrop, setLoginDrop] = React.useState(null);
    const open = Boolean(loginDrop);
    const handleClick = (event) => {
        setLoginDrop(event.currentTarget);
    };
    const handleClose = () => {
        setLoginDrop(null);
    };


    const { toggleColorMode } = useContext(ColorModeContext)
    //TODO: Lizzie: simply call the toggleColorMode function above on click to toggle the color mode


    return (
        <>
        <div style={{backgroundColor: 'black'}}><img src={gallyLogo} style={{padding: '15px 12px'}} height={50} alt="Galluadet University Logo" /></div>
        <ul id="navBar">
            {keycloak.tokenParsed.roles.includes("Admin") === true ? (<></>) : (<>
                <li><Link className='nav-items active' to={"./"}><Typography variant="nav"> Dashboard </Typography></Link></li>
                <li><Link className='nav-items' to={"./learn"}><Typography variant="nav"> Learn </Typography></Link></li>
                {/* <li><Link className='nav-items' to={"./schedule"}><Typography variant="nav"> Schedule </Typography></Link></li> */}
                <li><Link className='nav-items' to={"./Participants"}><Typography variant="nav"> Participants </Typography></Link></li>
                <li><Link className='nav-items' to={"./notes"}><Typography variant="nav"> Notes</Typography></Link></li>
                {/* <li><Link className='nav-items' to={"./resources"}><Typography variant="nav"> Resources </Typography></Link></li> */}    
            </>)} 
          <div style={{float: 'right'}} >
          <li style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                {/* <Switch inputProps={{role: 'switch', 'aria-label': "Dark Mode Toggle"}} color="secondary" onChange={() => {console.log(theme);}}/> */}
                <Button sx={{color: "white", border: "1px solid white"}} onClick={() => toggleColorMode()}>{theme.palette.mode == "light" ? "Dark Mode On" : "Dark Mode Off"}</Button>
          </li>
          <li id="login" className='nav-items' style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <IconButton
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <AccountCircle
                            sx={{
                                color: keycloak.authenticated
                                    ? "secondary.main"
                                    : "background.main",
                                fontSize: "40px",
                            }}
                        />
                    </IconButton>
                    <Menu
        id="basic-menu"
        anchorEl={loginDrop}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem aria-label={
                            keycloak.authenticated ? "Log Out" : "Log In"
                        }
                        onClick={
                            keycloak.authenticated
                                ? keycloak.logout
                                : keycloak.login
                        }>{keycloak.authenticated ? "Log Out" : "Log In"}</MenuItem>
      </Menu>
            </li>
            </div>
        </ul>
        </>
        // <>
        //     <AppBar
        //         sx={{
        //             padding: "12px",
        //             height: "70px",
        //             bgcolor: theme.palette.primary.main,
        //             display: "flex",
        //             flexDirection: "row",
        //             alignItems: "center",
        //             justifyContent: "space-between",
        //         }}
        //         position="sticky"
        //     >
        //         <Box
        //             sx={{
        //                 display: "flex",
        //                 flexDirection: "row",
        //             }}
        //         >
        //             <Box
        //                 sx={{
        //                     display: "flex",
        //                     bgcolor: "black",
        //                     height: "50px",
        //                     borderRadius: "10px",
        //                 }}
        //             >
        //                 <img src={gallyLogo} alt="Galluadet University Logo" />
        //             </Box>
        //             {keycloak.authenticated && (<Box
        //                 sx={{
        //                     flexGrow: 1,
        //                     display: "flex",
        //                     ml: 2,
        //                 }}
        //             >
        //                 {pages.map((page) => (
        //                     <Button
        //                         key={page}
        //                         onClick={() => {
        //                             navigation(
        //                                 page.toLowerCase() !== "dashboard"
        //                                     ? `/${page.toLowerCase()}`
        //                                     : "/"
        //                             );
        //                         }}
        //                         color={
        //                             location.pathname.split("/")[1] ===
        //                                 page.toLowerCase() ||
        //                             (page === "Dashboard" &&
        //                                 location.pathname === "/")
        //                                 ? "secondary"
        //                                 : "background"
        //                         }
        //                         sx={{
        //                             mx: 0.5,
        //                             // display: "block",
        //                         }}
        //                     >
        //                         {page}
        //                     </Button>
        //                 ))}
        //             </Box>)}
        //         </Box>
        //         <Box>
        //             <IconButton
        //                 aria-label={
        //                     keycloak.authenticated ? "Log Out" : "Log In"
        //                 }
        //                 onClick={
        //                     keycloak.authenticated
        //                         ? keycloak.logout
        //                         : keycloak.login
        //                 }
        //             >
        //                 <AccountCircle
        //                     sx={{
        //                         color: keycloak.authenticated
        //                             ? "secondary.main"
        //                             : "background.main",
        //                         fontSize: "35px",
        //                     }}
        //                 />
        //             </IconButton>
        //         </Box>
        //     </AppBar>
        // </>
    );
}

export default NavBar;
