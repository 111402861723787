import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

import { PlayCircle } from "@mui/icons-material";

import APICourse from "../APICourse";
export default function VideoCard({ course }) {
  const { keycloak } = useKeycloak();
  let { id, courseName, description } = course;
  const theme = useTheme();
  const [thumbnail, setThumbnail] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      //   const image = await APIVideo.get(`v1/media/file/thumbnail/course/${id}`, {
      //     headers: {
      //       Authorization: `Bearer ${keycloak.token}`,
      //       Range: "bytes=0-800",
      //     },
      //   });
      const image = await fetch(
        `https://chw-video-streaming-api.gallaudet.in/v1/media/file/thumbnail/course/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${keycloak.token}`,
            Range: "bytes=0-800",
          },
        }
      );
      const imageBlob = await image.blob();
      setThumbnail(URL.createObjectURL(imageBlob));
      setLoading(false);
    })();
  }, []);
  return (
    <div>
      <Card
        sx={{
          maxWidth: 427,
          borderRadius: "15px",
        }}
      >
        <CardActionArea
          onClick={() => navigate(`/learn/course/${id}`, { state: course })}
        >
          <CardMedia
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
            component="img"
            height="217"
            image={!loading && thumbnail}
            alt={courseName}
          />
          <PlayCircle
            sx={{
              color: theme.palette.background.paper,
              position: "absolute",
              top: (217 * 3) / 4,
              // "60%",
              left: "15%",
              transform: "translate(-50%, -50%)",
              fontSize: "85px",
              opacity: 0.7,
            }}
          />

          <CardContent sx={{ backgroundColor: "background.paper" }}>
            <Typography
              // sx={{ mb: 1 }}
              variant="h6"
            >
              {courseName}
            </Typography>
            {/* <Typography variant="body1">
                                {description}
                            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
}
